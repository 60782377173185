import { createContext } from "react";

import { BiaUser } from "../shared/routers/AdminRouter";

export const UserContext = createContext<BiaUser | undefined>(undefined);

export function isIntern(user: BiaUser) {
  return user.roles.findIndex((r) => r === "intern") !== -1;
}

export const UserPreferenceContext = createContext<Record<string, number>>({});
