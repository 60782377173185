import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FaLink } from "react-icons/fa";

import {
  Table,
  SortingState,
  VisibilityState,
  createColumnHelper,
} from "../core/Table";
import { Shot, ShotAggregate } from "../../../shared/routers/ShotRouter";
import { DetailsControl } from "../query/DetailsControl";
import { VideoControl } from "../query/VideoControl";
import { VideoModal } from "../video/VideoModal";
import {
  decFormat2,
  dec100Format,
  fractionFormat,
  plusMinusFormat2,
  dateFormatLong,
  gameClockFormat,
  seFormat2,
  decFormat,
} from "../../util/Format";
import {
  groupByMap,
  complexShotTypeMap,
  simpleShotTypeLabelMap,
  Periods,
  Positions,
  shotLabels,
  secondSpectrumContestLevels,
} from "../../constants/AppConstants";
import { SynergyEditorClip, shotToSynergyEditorClip } from "../video/utilities";
import { UserContext } from "../../UserContext";
import { PlayerTableCell, TeamTableCell } from "../core/TableCell";

const complexShotTypeLabels = Object.keys(complexShotTypeMap)
  .filter((key) => {
    const cst = complexShotTypeMap[key];
    return cst && cst.parent === undefined;
  })
  .reduce((map, key) => {
    const cst = complexShotTypeMap[key];
    if (cst) {
      map[key] = cst.label;
    }
    return map;
  }, {} as Record<string, string>);

const shotColumnHelper = createColumnHelper<Shot>();
const aggShotColumnHelper = createColumnHelper<ShotAggregate>();

export function ShotQueryResultsTable(props: {
  shots?: Shot[];
  shotsAgg?: ShotAggregate[];
  groupBy?: string[];
  onDetails?: (
    id: "left" | "right" | "",
    data: ShotAggregate,
    groupBy: string[]
  ) => void;
  onVideo?: (data: ShotAggregate, groupBy: string[]) => void;
  sorting?: SortingState;
  setSorting?: (sorting: SortingState) => void;
}) {
  const { shots, shotsAgg, groupBy, onDetails, onVideo, setSorting, sorting } =
    props;

  if (shots) {
    return (
      <IndividualShotTable
        data={shots}
        sorting={sorting}
        setSorting={setSorting}
      />
    );
  } else if (shotsAgg && groupBy && onDetails && onVideo) {
    return (
      <AggShotTable
        data={shotsAgg}
        groupBy={groupBy}
        onDetails={onDetails}
        onVideo={onVideo}
        sorting={sorting}
        setSorting={setSorting}
      />
    );
  }
  return null;
}

function IndividualShotTable(props: {
  data: Shot[];
  sorting?: SortingState;
  setSorting?: (sorting: SortingState) => void;
}) {
  const { data: shots, sorting, setSorting } = props;
  const [clips, setClips] = useState<SynergyEditorClip[]>();
  const user = useContext(UserContext);

  const columns = React.useMemo(
    () => [
      shotColumnHelper.accessor("shooter", {
        header: () => "Shooter",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.shooterId}
            name={info.getValue()}
          />
        ),
        meta: { group: 0 },
      }),
      shotColumnHelper.accessor("period", {
        header: () => "Period",
        cell: (info) => Periods[info.getValue()],
        meta: { group: 1 },
      }),
      shotColumnHelper.accessor("gameClock", {
        header: () => "Game Clock",
        cell: (info) => gameClockFormat(info.getValue()),
        meta: { group: 2 },
      }),
      shotColumnHelper.accessor("specificShotType", {
        header: () => "Specific Shot Type",
        cell: (info) => complexShotTypeLabels[info.getValue()],
        meta: { group: 3, textAlign: "left" },
      }),
      shotColumnHelper.accessor("generalShotType", {
        header: () => "General Shot Type",
        cell: (info) => simpleShotTypeLabelMap[info.getValue()],
        meta: { group: 4, textAlign: "left" },
      }),
      shotColumnHelper.accessor("made", {
        header: () => "Made",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: 5 },
      }),
      shotColumnHelper.accessor("isThree", {
        header: () => "3pt Shot",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: 6 },
      }),
      shotColumnHelper.accessor("contestLevel", {
        header: () => "Contest Level",
        cell: (info) => {
          const value = info.getValue();
          if (value) {
            return secondSpectrumContestLevels[
              value as keyof typeof secondSpectrumContestLevels
            ];
          }
          return "";
        },
        meta: { group: 7, textAlign: "left" },
      }),
      shotColumnHelper.accessor("fouled", {
        header: () => "Fouled",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: 8 },
      }),
      shotColumnHelper.accessor("dribblesBefore", {
        header: () => "Dribbles",
        meta: { group: 9 },
      }),
      shotColumnHelper.accessor("shotDist", {
        header: () => "Distance",
        cell: (info) => Math.round(info.getValue()),
        meta: { group: 10 },
      }),
      shotColumnHelper.accessor("epps", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: 11 },
      }),
      shotColumnHelper.accessor("eppsLeague", {
        header: () => "xPPS Lg",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: 11 },
      }),
      shotColumnHelper.accessor("synergyUrl", {
        header: () => "Video",
        cell: (info) => {
          return info.getValue() ? (
            <VideoControl
              data={info.row.original}
              onVideo={(d) => setClips([shotToSynergyEditorClip(d)])}
            />
          ) : (
            ""
          );
        },
        meta: { group: 12 },
      }),
      shotColumnHelper.accessor("shotId", {
        header: () => "Details",
        cell: (info) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Link to={`/shot/${info.getValue()}`} target="_blank">
                <FaLink />
              </Link>
            </div>
          );
        },
        meta: { group: 13 },
      }),
    ],
    []
  );

  const hiddenColumns = {
    shotId: user && user.roles.includes("admin") ? true : false,
  };

  return (
    <div>
      <Table
        columns={columns}
        data={shots}
        hiddenColumns={hiddenColumns}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        autoWidth={true}
      />
      <VideoModal
        clips={clips || []}
        title="Shot Explorer"
        show={!!clips}
        handleClose={() => setClips(undefined)}
        upDownClipSkip={true}
        showSynergyEditor={true}
      />
    </div>
  );
}

function AggShotTable(props: {
  data: ShotAggregate[];
  groupBy: string[];
  onDetails: (
    id: "left" | "right" | "",
    data: ShotAggregate,
    groupBy: string[]
  ) => void;
  onVideo: (data: ShotAggregate, groupBy: string[]) => void;
  sorting?: SortingState;
  setSorting?: (sorting: SortingState) => void;
}) {
  const {
    data: shotsAgg,
    groupBy,
    onDetails,
    onVideo,
    sorting,
    setSorting,
  } = props;
  const columns = React.useMemo(() => {
    let g = 0;
    return [
      aggShotColumnHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => info.getValue(),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("isRegularSeason", {
        header: () => "Playoffs",
        cell: (info) => (info.getValue() ? "No" : "Yes"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("oteam", {
        header: () => "Offense Team",
        cell: (info) => (
          <TeamTableCell
            ids={info.row.original.oteamId}
            name={info.getValue()}
          />
        ),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("dteam", {
        header: () => "Defense Team",
        cell: (info) => (
          <TeamTableCell
            ids={info.row.original.dteamId}
            name={info.getValue()}
          />
        ),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("shooter", {
        header: () => "Shooter",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.shooterId}
            name={info.getValue()}
          />
        ),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("passer", {
        header: () => "Passer",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.passerId || undefined}
            name={info.getValue() || undefined}
          />
        ),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("defender", {
        header: () => "Defender",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.defenderId}
            name={info.getValue()}
          />
        ),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("positionShooter", {
        header: () => "Position (At Time)",
        cell: (info) => Positions[info.getValue() || -1] || info.getValue(),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("jointPosition", {
        header: () => "Position (Typical)",
        cell: (info) => Positions[info.getValue() || -1] || info.getValue(),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("positionDefender", {
        header: () => "Defender Position (At Time)",
        cell: (info) => Positions[info.getValue() || -1] || info.getValue(),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("jointPositionDef", {
        header: () => "Defender Position (Typical)",
        cell: (info) => Positions[info.getValue() || -1] || info.getValue(),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => {
          const date = info.getValue();
          if (date) {
            return dateFormatLong(new Date(`${date}T00:00:00`));
          }
          return "";
        },
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("gameId", {
        header: () => "Game",
        cell: (info) => (
          <Link to={`/game/${info.getValue()}`}>
            {info.row.original.gameString}
          </Link>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      aggShotColumnHelper.accessor("period", {
        header: () => "Period",
        cell: (info) => {
          const p = info.getValue();
          if (p === undefined) return "";
          return Periods[p];
        },
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("transition", {
        header: () => "Transition",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("leftSide", {
        header: () => "Left Side",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("oreb2024", {
        header: () => "After OREB 20-24",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("fouled", {
        header: () => "Fouled",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("isThree", {
        header: () => "3PT Shot",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("contestLevel", {
        header: () => "Contest Level",
        cell: (info) => {
          const value = info.getValue();
          if (value) {
            return secondSpectrumContestLevels[
              value as keyof typeof secondSpectrumContestLevels
            ];
          }
          return "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      aggShotColumnHelper.accessor("made", {
        header: () => "Made",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("blocked", {
        header: () => "Blocked",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("putback", {
        header: () => "Putback",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("aboveTheBreak", {
        header: () => "Above the Break",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("corner", {
        header: () => "Corner",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("afterTimeout", {
        header: () => "After Timeout",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("specificShotType", {
        header: () => "Specific Shot Type",
        cell: (info) => complexShotTypeLabels[info.getValue() || ""] || "",
        meta: { group: g++, textAlign: "left" },
      }),
      aggShotColumnHelper.accessor("generalShotType", {
        header: () => "General Shot Type",
        cell: (info) => simpleShotTypeLabelMap[info.getValue() || ""] || "",
        meta: { group: g++, textAlign: "left" },
      }),
      aggShotColumnHelper.accessor("direction", {
        header: () => "C&S Direction",
        cell: (info) => shotLabels.direction[info.getValue() || ""] || "",
        meta: { group: g++, textAlign: "left" },
      }),
      aggShotColumnHelper.accessor("driveDirection", {
        header: () => "Drive Direction",
        cell: (info) => shotLabels.drive_direction[info.getValue() || ""] || "",
        meta: { group: g++, textAlign: "left" },
      }),
      aggShotColumnHelper.accessor("dribblesBefore", {
        header: () => "Dribbles",
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("numShots", {
        header: () => "# Shots",
        cell: (info) => info.getValue().toLocaleString(),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor((row) => row.fg2m / (row.fg2a || 1), {
        id: "fgm2a",
        header: () => "2PM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original.fg2a,
            numerator: info.row.original.fg2m,
          }),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor((row) => row.fg2m / (row.fg2a || 1), {
        id: "fg2pct",
        header: () => "2P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor((row) => row.fg3m / (row.fg3a || 1), {
        id: "fgm3a",
        header: () => "3PM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original.fg3a,
            numerator: info.row.original.fg3m,
          }),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor((row) => row.fg3m / (row.fg3a || 1), {
        id: "fg3pct",
        header: () => "3P%",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("efg", {
        header: () => "EFG%",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("ftarate", {
        header: () => "FTA Rate",
        cell: (info) => decFormat(info.getValue() * 100),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("xpps", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("xppslg", {
        header: () => "xPPS Lg",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor((row) => row.xpps - row.xppslg, {
        id: "delta",
        header: () => "∆",
        cell: (info) => plusMinusFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("pps", {
        header: () => "PPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("ppsSE", {
        header: () => "SE",
        cell: (info) => seFormat2(info.getValue()),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor((row) => row.pps - row.xpps, {
        id: "luck",
        header: () => "Luck",
        cell: (info) => plusMinusFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.accessor("layupPct", {
        header: () => "% Layup",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("nonLayup2Pct", {
        header: () => "% Non-Layup 2",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: g },
      }),
      aggShotColumnHelper.accessor("threePct", {
        header: () => "% 3",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.display({
        id: "video",
        header: () => "Video",
        cell: (info) => (
          <VideoControl
            data={info.row.original}
            onVideo={(d) => onVideo(d, groupBy)}
          />
        ),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.display({
        id: "left",
        header: () => "L",
        cell: (info) => (
          <DetailsControl
            data={info.row.original}
            onDetails={(i, d) => onDetails(i, d, groupBy)}
            id={"left"}
          />
        ),
        meta: { group: g++ },
      }),
      aggShotColumnHelper.display({
        id: "right",
        header: () => "R",
        cell: (info) => (
          <DetailsControl
            data={info.row.original}
            onDetails={(i, d) => onDetails(i, d, groupBy)}
            id={"right"}
          />
        ),
        meta: { group: g++ },
      }),
    ];
  }, [groupBy, onDetails, onVideo]);
  return (
    <Table
      columns={columns}
      data={shotsAgg}
      hiddenColumns={visibilityStatesFromGroupBy(groupBy)}
      sorting={sorting}
      setSorting={setSorting}
      virtualScroll={true}
      autoWidth={true}
    />
  );
}

function visibilityStatesFromGroupBy(groupBy: string[]) {
  const visibility: VisibilityState = {};
  // Don't show group by columns that we arean't grouping by.
  for (const g of groupByMap.map((gb) => gb.value)) {
    if (!groupBy.includes(g)) {
      visibility[g] = false;
      if (g === "gameString") {
        visibility["gameId"] = false;
        visibility["gameDate"] = false;
      }
    }
  }
  return visibility;
}
