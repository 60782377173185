import React, { useMemo, useState } from "react";
import { Button, Form, Col, Row, Alert } from "react-bootstrap";
import {
  IoMdClose,
  IoMdCreate,
  IoMdEye,
  IoMdEyeOff,
  IoMdRefresh,
} from "react-icons/io";
import moment from "moment";

import { Modal } from "../components/core/Modal";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { Table, createColumnHelper } from "../components/core/Table";
import { TableCellButton } from "../components/core/TableCellButton";
import { BiaUser, NewBiaUser } from "../../shared/routers/AdminRouter";
import { trpc } from "../util/tRPC";
import { MultiSelect } from "../components/core/MultiSelect";

const columnHelper = createColumnHelper<BiaUser>();

export function AdminPage() {
  const utils = trpc.useContext();
  const [userToReset, setUserToReset] = useState<BiaUser>();
  const [resetPassword, setResetPassword] = useState<string>();
  const [hidePassword, setHidePassword] = useState(true);
  const [statusMsg, setStatusMsg] = useState<{ msg: string; error: boolean }>();
  const [userToEdit, setUserToEdit] = useState<BiaUser>();
  const [userToDelete, setUserToDelete] = useState<BiaUser>();
  const [newUser, setNewUser] = useState<NewBiaUser>({
    name: "",
    email: "",
    password: "",
    roles: [],
    forceNewPassword: true,
  });

  const userQuery = trpc.admin.getBiaUsers.useQuery();
  const createUserMutation = trpc.admin.createUser.useMutation({
    onSettled: () => {
      utils.admin.invalidate();
      utils.admin.getBiaUsers.refetch();
    },
  });
  const deleteUserMutation = trpc.admin.deleteUser.useMutation({
    onSettled: () => {
      utils.admin.invalidate();
      utils.admin.getBiaUsers.refetch();
    },
  });
  const updateUserMutation = trpc.admin.updateUser.useMutation({
    onSettled: () => {
      utils.admin.invalidate();
      utils.admin.getBiaUsers.refetch();
    },
  });
  const resetPasswordMutation = trpc.admin.resetPassword.useMutation({
    onSettled: () => {
      utils.admin.invalidate();
      utils.admin.getBiaUsers.refetch();
    },
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: () => "User",
      }),
      columnHelper.accessor("email", {
        header: () => "Email",
      }),
      columnHelper.accessor("roles", {
        header: () => "Roles",
        cell: (info) => info.getValue().join(", "),
      }),
      columnHelper.accessor("created", {
        header: () => "Created",
        cell: (info) => moment(info.getValue()).format("MMM Do YYYY"),
      }),
      columnHelper.accessor("forceNewPassword", {
        header: () => "Needs New Password",
        cell: (info) => (info.getValue() ? "Yes" : ""),
      }),
      columnHelper.display({
        id: "reset",
        header: () => "Reset Password",
        cell: (info) => (
          <TableCellButton
            onClick={() => {
              setUserToReset(info.row.original);
              setResetPassword(generatePassword());
            }}
          >
            <IoMdRefresh />
          </TableCellButton>
        ),
      }),
      columnHelper.display({
        id: "edit",
        header: () => "Edit",
        cell: (info) => (
          <TableCellButton
            onClick={() => setUserToEdit({ ...info.row.original })}
          >
            <IoMdCreate />
          </TableCellButton>
        ),
      }),
      columnHelper.display({
        id: "delete",
        header: () => "Delete",
        cell: (info) => (
          <TableCellButton onClick={() => setUserToDelete(info.row.original)}>
            <IoMdClose />
          </TableCellButton>
        ),
      }),
    ],
    []
  );

  const data = userQuery.data;

  return (
    <Page header={{ text: "Admin Page" }} title="Admin">
      <div>
        <Row>
          <Col xs={12}>
            <Panel header={"Users"}>
              <>
                {resetPassword && userToReset && (
                  <Modal
                    show={!!resetPassword}
                    title="Reset Password"
                    content={
                      <span>
                        {`Update the password for ${userToReset.email} to:`}{" "}
                        <b>{resetPassword}</b>
                      </span>
                    }
                    handleClose={() => {
                      setResetPassword(undefined);
                      setUserToReset(undefined);
                    }}
                    handleConfirm={() => {
                      resetPasswordMutation
                        .mutateAsync({
                          password: resetPassword,
                          userId: userToReset._id,
                        })
                        .then(() => {
                          setResetPassword(undefined);
                          setUserToReset(undefined);
                        });
                    }}
                  />
                )}
                {userToEdit && (
                  <Modal
                    show={!!userToEdit}
                    title="Edit User"
                    content={
                      <UserEditForm
                        userToEdit={userToEdit}
                        setUserToEdit={setUserToEdit}
                      />
                    }
                    handleClose={() => setUserToEdit(undefined)}
                    handleConfirm={() => {
                      updateUserMutation
                        .mutateAsync({
                          userId: userToEdit._id,
                          editedUser: userToEdit,
                        })
                        .then(() => {
                          setUserToEdit(undefined);
                        });
                    }}
                  />
                )}
                {userToDelete && (
                  <Modal
                    show={!!userToDelete}
                    title="Delete User"
                    content={`Are you sure you want to delete ${userToDelete.email}?`}
                    handleClose={() => setUserToDelete(undefined)}
                    handleConfirm={() => {
                      deleteUserMutation
                        .mutateAsync({ userId: userToDelete._id })
                        .then(() => {
                          setUserToDelete(undefined);
                        });
                    }}
                  />
                )}
                {data ? (
                  <Table
                    data={data.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    columns={columns}
                    showRowIndex={false}
                    autoWidth={true}
                  />
                ) : (
                  <Spinner />
                )}
              </>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Panel header={"Create New User"}>
              <Form autoComplete={"off"}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={newUser.name}
                  onChange={(e) => {
                    const newUserCopy = { ...newUser };
                    newUserCopy.name = e.currentTarget.value;
                    setNewUser(newUserCopy);
                  }}
                />
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={newUser.email}
                  onChange={(e) => {
                    const newUserCopy = { ...newUser };
                    newUserCopy.email = e.currentTarget.value;
                    setNewUser(newUserCopy);
                  }}
                />
                <Form.Label>Password</Form.Label>
                <Button
                  style={{
                    marginLeft: 8,
                    lineHeight: 0,
                    padding: 3,
                    fontSize: "0.7em",
                  }}
                  onClick={() => {
                    const newUserCopy = { ...newUser };
                    newUserCopy.password = generatePassword();
                    setNewUser(newUserCopy);
                    setHidePassword(false);
                  }}
                >
                  <IoMdRefresh />
                </Button>
                <Button
                  style={{
                    marginLeft: 8,
                    lineHeight: 0,
                    padding: 3,
                    fontSize: "0.7em",
                  }}
                  onClick={() => setHidePassword(!hidePassword)}
                  title={hidePassword ? "Show Password" : "Hide Password"}
                >
                  {hidePassword ? <IoMdEye /> : <IoMdEyeOff />}
                </Button>
                <Form.Control
                  type={hidePassword ? "password" : "text"}
                  value={newUser.password}
                  onChange={(e) => {
                    const newUserCopy = { ...newUser };
                    newUserCopy.password = e.currentTarget.value;
                    setNewUser(newUserCopy);
                  }}
                />
                <Form.Label>Force New Password</Form.Label>
                <Form.Check
                  checked={!!newUser.forceNewPassword}
                  onChange={() => {
                    const newUserCopy = { ...newUser };
                    newUserCopy.forceNewPassword = !newUser.forceNewPassword;
                    setNewUser(newUserCopy);
                  }}
                />
                <Form.Label>Roles</Form.Label>
                <MultiSelect
                  values={["admin", "bia", "scout", "intern"].map((r) => {
                    return { value: r, label: r };
                  })}
                  selected={newUser.roles}
                  onChange={(vals) => {
                    const newUserCopy = { ...newUser };
                    newUserCopy.roles = vals;
                    setNewUser(newUserCopy);
                  }}
                />
                <Button
                  style={{ marginTop: 16, marginBottom: 16 }}
                  onClick={() => {
                    createUserMutation
                      .mutateAsync({
                        newUser: newUser,
                      })
                      .then((res) => {
                        if (res.err) {
                          setStatusMsg({
                            msg: `Failed to create new user: ${newUser.email}`,
                            error: true,
                          });
                        } else {
                          setStatusMsg({
                            msg: `Successfully created new user: ${newUser.email}`,
                            error: false,
                          });
                          setNewUser({
                            name: "",
                            email: "",
                            password: "",
                            roles: [],
                            forceNewPassword: true,
                          });
                        }
                      });
                  }}
                  disabled={
                    !newUser.email || !newUser.email || !newUser.password
                  }
                >
                  Create User
                </Button>
                {statusMsg && (
                  <Alert variant={statusMsg.error ? "danger" : "success"}>
                    {statusMsg.msg}
                  </Alert>
                )}
              </Form>
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

function UserEditForm(props: {
  userToEdit: BiaUser;
  setUserToEdit: (user: BiaUser) => void;
}) {
  const { userToEdit, setUserToEdit } = props;

  return (
    <div>
      <Form.Label>Name</Form.Label>
      <Form.Control
        value={userToEdit.name}
        onChange={(e) => {
          const updatedUserCopy = { ...userToEdit };
          updatedUserCopy.name = e.currentTarget.value;
          setUserToEdit(updatedUserCopy);
        }}
      />
      <Form.Label>Email</Form.Label>
      <Form.Control
        value={userToEdit.email}
        onChange={(e) => {
          const updatedUserCopy = { ...userToEdit };
          updatedUserCopy.email = e.currentTarget.value;
          setUserToEdit(updatedUserCopy);
        }}
      />
      <Form.Label>Roles</Form.Label>
      <MultiSelect
        values={["admin", "bia", "scout", "intern"].map((r) => {
          return { value: r, label: r };
        })}
        selected={userToEdit.roles}
        onChange={(vals) => {
          const updatedUserCopy = { ...userToEdit };
          updatedUserCopy.roles = vals;
          setUserToEdit(updatedUserCopy);
        }}
      />
      <Form.Label>Force New Password</Form.Label>
      <Form.Check
        checked={userToEdit.forceNewPassword}
        onChange={() => {
          const updatedUserCopy = { ...userToEdit };
          updatedUserCopy.forceNewPassword = !updatedUserCopy.forceNewPassword;
          setUserToEdit(updatedUserCopy);
        }}
      />
    </div>
  );
}

function generatePassword() {
  const length = 15;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$";
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}
