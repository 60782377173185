import React, { ReactNode, useContext } from "react";

import { UserContext } from "../../UserContext";

export function Restrict(props: {
  children: ReactNode;
  roles: string[];
  emails?: string[];
  override?: boolean;
}) {
  const { children, roles, emails, override } = props;
  const user = useContext(UserContext);

  if (
    (user && roles.some((r) => user.roles.includes(r))) ||
    override ||
    (user && emails && emails.includes(user.email))
  ) {
    return <>{children}</>;
  }
  return null;
}
