import React from "react";

export function TeamLogo(props: {
  idsId?: number; // Team ID e.g. 1610612738 for Celtics.
  espnId?: number;
  isNcaa?: boolean;
  isGLeague?: boolean;
  size?: number;
  inline?: boolean;
  className?: string;
}) {
  const { idsId, espnId, className, isNcaa, isGLeague } = props;
  let { size, inline } = props;

  size = size || 80;
  inline = inline === undefined ? false : inline;

  const originalSize = 400;
  const scaleSize = size / originalSize;

  return (
    <div
      className={className}
      style={{
        width: size,
        height: size,
        float: inline ? "left" : undefined,
        marginRight: inline ? size / 10 : undefined,
        display: "inline-block",
        overflow: "hidden",
      }}
    >
      {idsId ? (
        isGLeague ? (
          <img
            width={size}
            src={`https://ak-static.cms.nba.com/wp-content/uploads/logos/nbagleague/${idsId}/primary/D/logo.svg`}
          />
        ) : (
          <div
            className={`team-logo logo-sprite logo-sprite-${idsId}`}
            style={{
              transform: `scale(${scaleSize}, ${scaleSize})`,
            }}
          ></div>
        )
      ) : espnId && isNcaa ? (
        <img
          width={size}
          src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa/500/${espnId}.png`}
        />
      ) : null}
    </div>
  );
}
