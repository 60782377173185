import React, { useMemo, useState } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  seasonString,
  makePercent,
  decFormat,
  pctFormat,
} from "../../util/Format";
import AppContext from "../../../shared/AppContext";
import { Highlights } from "../../constants/AppConstants";
import { PlayerSkillModelCombined } from "../../../shared/routers/PlayerRouter";
import { TeamTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<PlayerSkillModelCombined>();

export function PlayerSkillModelCombinedTable(props: {
  data: PlayerSkillModelCombined[];
  type: "peak" | "season";
}) {
  const { data, type } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("Season", {
        header: "Season",
        cell: (info) =>
          `${info.row.original.full_data ? "" : "! "}${seasonString(
            info.getValue().toString()
          )}`,
        meta: { group: g },
      }),
      columnHelper.accessor("League", {
        header: "League",
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("Team", {
        header: "Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue() || undefined}
            id={info.row.original.teamID || undefined}
            season={info.row.original.Season.toString()}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("DraftEligible", {
        header: "Draft Eligible",
        cell: (info) => (info.getValue() ? "Y" : ""),
        meta: { group: g++ },
      }),
      columnHelper.accessor("pNBA", {
        header: "pNBA",
        cell: (info) => makePercent(decFormat)(info.getValue()),
        meta: {
          group: g,
          colorDomain: [0.0183, 0.6265],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("oskill", {
        header: "Offense",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          colorDomain: [-0.0359 * 100, 0.0599 * 100],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("dskill", {
        header: "Defense",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          colorDomain: [-0.0403 * 100, 0.0555 * 100],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("skill", {
        header: "Projected Impact",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          colorDomain: [-0.0592 * 100, 0.1106 * 100],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("projectedAvailability", {
        header: "Proj. Availability",
        cell: (info) => pctFormat(info.getValue()),
        meta: { group: g++ },
      }),
    ];
  }, []);

  const leagueColors: Record<string, string> = {
    NBA: "#fff",
    NCAA: "rgb(237, 246, 255)",
    "NBA Preseason": "rgb(240, 240, 240)",
    "D-League": "rgb(238, 253, 228)",
    "Utah Summer": "rgb(227, 249, 244)",
    "Vegas Summer": "rgb(227, 249, 244)",
    "Orlando Summer": "rgb(227, 249, 244)",
    "California Classic": "rgb(227, 249, 244)",
    other: "rgb(237, 236, 255)",
  };

  const rowColorMap: Record<
    number,
    { backgroundColor?: string; color?: string }
  > = {};

  data.forEach((d, i) => {
    // Handle rows with multiple teams.
    const league = d.League;
    const fulldata = d.full_data;
    const season = d.Season;

    const color: { backgroundColor?: string; color?: string } = {};

    if (!league && season < parseInt(AppContext.currentSeason)) {
      color.backgroundColor = "rgb(237, 236, 255)";
    } else if (!league) {
      color.backgroundColor = "rgb(245, 228, 228)";
    } else if (league == "NBA" && season < 2014) {
      color.backgroundColor = "rgb(240, 240, 240)";
    } else {
      color.backgroundColor = leagueColors[league] || leagueColors.other;
    }

    if (fulldata === false) {
      color.color = "maroon";
    }

    rowColorMap[i] = color;
  });

  return (
    <Table
      data={data}
      columns={columns}
      rowColorMap={rowColorMap}
      sorting={sorting}
      setSorting={setSorting}
      hiddenColumns={{
        pNBA: type === "peak" ? true : false,
        projectedAvailability: type === "peak" ? false : true,
      }}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
