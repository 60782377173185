import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/styles";
import cx from "classnames";

import { TeamSchedule } from "../../../shared/routers/TeamRouter";
import {
  timeFormat,
  dateFormatShort,
  plusMinusFormat,
  decFormat2,
} from "../../util/Format";
import { trpc } from "../../util/tRPC";

// Number of previous / upcoming games to show.
const NUM_OF_GAMES = 5;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: "left",
      padding: 6,
      borderRadius: 4,
      marginRight: 8,
      marginBottom: 0,
      display: "inline-block",
      verticalAlign: "top",
    },
    nextContainer: {
      marginTop: 21, // Make this align with prev games.
      marginBottom: 0,
      marginRight: 0,
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      "& td": {
        padding: "1px 2px 0",
        fontSize: ".9em",
      },
    },
    leagueLuck: {
      textAlign: "center",
      color: "#8B8F99",
      fontSize: ".9em",
      fontWeight: "unset",
    },
    luckLabel: {
      color: "#8B8F99",
      textAlign: "right",
    },
    barLabel: {
      color: "#8B8F99",
      textAlign: "center",
    },
    dateCell: {
      textAlign: "left",
      width: 40,
    },
    homeAwayCell: {
      textAlign: "center",
      width: 23,
    },
    oppCell: {
      textAlign: "left",
      width: 35,
    },
    wonLostCell: {
      textAlign: "center",
      width: 35,
    },
    teamScoreCell: {
      textAlign: "right",
      width: 28,
      "&.won": {
        fontWeight: 600,
      },
    },
    oppScoreCell: {
      textAlign: "left",
      width: 28,
      "&.won": {
        fontWeight: 600,
      },
    },
    luckCell: {
      textAlign: "right",
    },
    pointDifferentialCell: {
      height: 12,
      "& svg": {
        height: 12,
      },
      "& #endarrowloss": {
        fill: "#C73A51",
      },
      "& #endarrowwin": {
        fill: "#50BF3D",
      },
    },
    pointDifferentialBar: {
      "&.won": {
        stroke: "#50BF3D",
        backgroundColor: "#50BF3D",
      },
      "&.loss": {
        stroke: "#C73A51",
        backgroundColor: "#C73A51",
      },
    },
    timeCell: {
      paddingLeft: 12,
      textAlign: "right",
      width: 66,
    },
  })
);

export function TeamLastNextNba(props: {
  teamId: number;
  data: TeamSchedule[];
}) {
  const classes = useStyles();
  const { data, teamId } = props;

  const { data: leaguePppData } = trpc.league.getLeaguePpp.useQuery();

  const leaguePpp = leaguePppData && leaguePppData[0];

  const { data: scheduleNext } = trpc.team.getTeamScheduleNext.useQuery({
    teamId: teamId.toString(),
  });

  const { data: seriesForSeason } = trpc.series.getSeriesForSeason.useQuery({
    teamId,
  });

  if (!leaguePpp || !scheduleNext) return null;

  // Data is an array of games ordered from oldest to most recent for the
  // most recent season.

  // The last 5 games.
  const last = data
    .filter((s) => moment().isAfter(s.gameDateTime, "day"))
    .slice(-NUM_OF_GAMES);

  const nextGames =
    scheduleNext.length === 0 ? null : (
      <div className={cx(classes.container, classes.nextContainer)}>
        <table className={classes.table}>
          <tbody style={{ whiteSpace: "nowrap" }}>
            {scheduleNext.map((game, i) => {
              const isHome = game.homeTeamId === teamId;
              const opp = isHome
                ? game.awayTeamAbbreviation
                : game.homeTeamAbbreviation;
              const oppTeamId = isHome ? game.awayTeamId : game.homeTeamId;
              return (
                <tr key={i}>
                  <td className={classes.dateCell}>
                    {dateFormatShort(moment(game.gameDateTime).toDate())}
                  </td>
                  <td className={classes.homeAwayCell}>
                    <Link to={`/preview/${game.gameid}`}>
                      {isHome ? "vs." : "@"}
                    </Link>
                  </td>
                  <td className={classes.oppCell}>
                    <Link to={`/team/${oppTeamId}`}>{opp}</Link>
                  </td>
                  <td className={classes.timeCell}>
                    {timeFormat(moment(game.gameDateTime).toDate())}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={classes.container}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th />
                <th className={classes.leagueLuck}>
                  League: {decFormat2(leaguePpp.ppp)}
                </th>
              </tr>
            </thead>
            <tbody>
              {last.map((game, i) => {
                const gameId = game.gameid;
                const isHome = game.homeTeamId === teamId;
                const opp = isHome
                  ? game.awayTeamAbbreviation
                  : game.homeTeamAbbreviation;
                const wonGame = game.winningteamid === teamId;
                const teamScore = isHome ? game.homescore : game.awayScore;
                const oppScore = isHome ? game.awayScore : game.homescore;
                const teamxPPP = isHome ? game.xPPPHome : game.xPPPVis;
                const oppxPPP = isHome ? game.xPPPVis : game.xPPPHome;
                const oppTeamId = isHome ? game.awayTeamId : game.homeTeamId;

                const teamLuck =
                  game.homeLuck === null
                    ? 0
                    : isHome
                    ? game.homeLuck
                    : -game.homeLuck;

                const zeroOneBound = (number: number) =>
                  Math.min(Math.max(0, number), 1);
                const minPPP = leaguePpp.ppp - 0.2;
                const maxPPP = leaguePpp.ppp + 0.2;
                const range = maxPPP - minPPP;
                const teamPercOfRange = teamxPPP
                  ? zeroOneBound((teamxPPP - minPPP) / range)
                  : 0;
                const oppPercOfRange = oppxPPP
                  ? zeroOneBound((oppxPPP - minPPP) / range)
                  : 0;
                const chartWidth = 240;
                const wonXPPP =
                  teamxPPP && oppxPPP ? teamxPPP > oppxPPP : false;
                const teamDist = wonXPPP
                  ? chartWidth * teamPercOfRange - 4
                  : chartWidth * teamPercOfRange;
                const oppDist = wonXPPP
                  ? chartWidth * oppPercOfRange
                  : chartWidth * oppPercOfRange + 4; // Adjust for arrow head.

                return (
                  <tr key={i} className={wonGame ? "team-won" : "opp-won"}>
                    <td className={classes.dateCell}>
                      {dateFormatShort(moment(game.gameDateTime).toDate())}
                    </td>
                    <td className={classes.homeAwayCell}>
                      {isHome ? "vs." : "@"}
                    </td>
                    <td className={classes.oppCell}>
                      <Link to={`/team/${oppTeamId}`}>{opp}</Link>
                    </td>
                    <td className={classes.wonLostCell}>
                      <Link title={"Go to game page"} to={`/game/${gameId}`}>
                        {wonGame ? "W" : "L"}
                      </Link>
                    </td>
                    <td className={cx(classes.teamScoreCell, { won: wonGame })}>
                      {teamScore}
                    </td>
                    <td>-</td>
                    <td className={cx(classes.oppScoreCell, { won: !wonGame })}>
                      {oppScore}
                    </td>
                    {teamxPPP === null ? null : (
                      <td className={classes.luckCell}>
                        {plusMinusFormat(teamLuck)}
                      </td>
                    )}
                    {teamxPPP === null ? null : (
                      <td
                        className={classes.pointDifferentialCell}
                        style={{ width: chartWidth }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: chartWidth }}
                        >
                          <defs>
                            <marker
                              id="endarrowwin"
                              orient="auto"
                              refX="1"
                              refY="2"
                            >
                              <circle cx="1" cy="2" r="1" />
                            </marker>
                            <marker
                              id="endarrowloss"
                              orient="auto"
                              refX="1"
                              refY="2"
                            >
                              <circle cx="1" cy="2" r="1" />
                            </marker>
                          </defs>
                          <line
                            x1={`${0.5 * chartWidth}`}
                            y1="0"
                            x2={`${0.5 * chartWidth}`}
                            y2="12"
                            stroke="#8B8F99"
                            strokeWidth="1"
                          />
                          <line
                            x1={`${oppDist}`}
                            y1="6"
                            x2={`${teamDist}`}
                            y2="6"
                            strokeWidth="4"
                            markerEnd={
                              wonXPPP
                                ? "url(#endarrowwin)"
                                : "url(#endarrowloss)"
                            }
                            className={cx(
                              classes.pointDifferentialBar,
                              { won: wonXPPP },
                              { loss: !wonXPPP }
                            )}
                          />
                        </svg>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td className={classes.luckLabel}>Luck</td>
                <td className={classes.barLabel}>xPPP scored and allowed</td>
              </tr>
            </tfoot>
          </table>
        </div>
        {nextGames}
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        {(seriesForSeason || [])
          .sort((a, b) => a.seriesId - b.seriesId)
          .map((s, i) => (
            <>
              <Link
                to={`/series/${s.seriesId}`}
                style={{ whiteSpace: "nowrap" }}
              >
                Round {i + 1} vs {s.oppTeamAbb}
              </Link>
              {i < (seriesForSeason || []).length - 1 && <span>•</span>}
            </>
          ))}
      </div>
    </>
  );
}
