import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { BsQuestionCircleFill, BsStar } from "react-icons/bs";
import { Link } from "react-router-dom";

import { PlayerBio } from "../../../shared/routers/PlayerRouter";
import { TooltipItem } from "../core/TooltipItem";
import { dec100Format, seFormat } from "../../util/Format";
import { Positions } from "../../constants/AppConstants";
import { trpc } from "../../util/tRPC";

const useStyles = makeStyles(() =>
  createStyles({
    impactLink: {
      textDecoration: "none",
      color: "unset",
      cursor: "pointer",
      ["@media (max-width:768px)"]: {
        margin: "0 auto",
        width: "fit-content",
      },
      "&:hover": {
        cursor: "pointer",
        color: "unset",
      },
    },
    playerImpactByPos: {
      backgroundColor: "rgba(255, 255, 255, 0.11)",
      padding: 3,
      borderRadius: 4,
      marginTop: 8,
    },
    playerImpactForecast: {
      display: "grid",
      gridTemplateColumns: "auto auto auto auto",
      backgroundColor: "rgba(255, 255, 255, 0.11)",
      padding: 3,
      borderRadius: 4,
    },
    netLine: {
      display: "flex",
      alignItems: "center",
    },
    netImpact: {
      margin: 0,
      fontWeight: 500,
      fontSize: 40,
    },
    netSE: {
      marginLeft: 6,
      fontSize: "1.1em",
    },
    forecastValueNet: {
      textAlign: "left",
      paddingLeft: 4,
      gridColumnStart: "span 1",
      fontWeight: 200,
      marginTop: "auto",
      marginBottom: "auto",
      padding: ".2em .6em .45em",
    },
    forecastValue: {
      gridColumnStart: "span 1",
      textAlign: "center",
      fontWeight: 200,
      marginTop: "auto",
      marginBottom: "auto",
      padding: ".2em .6em .45em",
    },
    forecastValueNumber: {
      margin: "0 0 3px",
      fontWeight: 400,
      fontSize: 20,
    },
    forecastLabel: {
      margin: 0,
      opacity: 0.6,
      fontSize: "1.0em",
    },
  })
);

export function PlayerImpact(props: {
  bio: PlayerBio;
  handleImpactBreakdownClick: () => void;
}) {
  const classes = useStyles();
  const { bio, handleImpactBreakdownClick } = props;

  const playerId = bio.playerId;

  const { data: impactByPos } = trpc.impact.getPlayerImpactByPosition.useQuery({
    playerId,
  });

  const { data: impactByRole } = trpc.impact.getPlayerImpactByRole.useQuery({
    playerId,
  });

  const sortedImpactByRole = (impactByRole || []).sort(
    (a, b) => b.off_impact + b.def_impact - (a.off_impact + a.def_impact)
  );

  const firstSortedImpactByRole = sortedImpactByRole[0];

  const maxImpact = firstSortedImpactByRole
    ? firstSortedImpactByRole.off_impact + firstSortedImpactByRole.def_impact
    : undefined;

  if (bio.showOnTop === 0) {
    return (
      <div
        className={classes.playerImpactForecast}
        style={{ width: 185, textAlign: "center" }}
      >
        <p>
          {`Insufficient recent possessions (${Math.round(
            bio.nPoss || 0
          )} / 500) to display Impact numbers`}
        </p>
      </div>
    );
  }
  return (
    <div style={{ width: "max-content" }}>
      <Link className={classes.impactLink} to={`/stats/netImpact`}>
        <div className={classes.playerImpactForecast}>
          <div className={classes.forecastValueNet}>
            <div className={classes.netLine}>
              <h1 className={classes.netImpact}>
                {dec100Format(bio.netImpact || 0)}
              </h1>
              <span className={classes.netSE}>
                {seFormat(100 * 1.96 * (bio.netSE || 0))}
              </span>
            </div>
            <h6 className={classes.forecastLabel}>Net Impact</h6>
          </div>
          <div className={classes.forecastValue}>
            <h3 className={classes.forecastValueNumber}>
              {dec100Format(bio.offImpact || 0)}
            </h3>
            <h6 className={classes.forecastLabel}>OFF</h6>
          </div>
          <div className={classes.forecastValue}>
            <h3 className={classes.forecastValueNumber}>
              {dec100Format(bio.defImpact || 0)}
            </h3>
            <h6 className={classes.forecastLabel}>DEF</h6>
          </div>
        </div>
      </Link>
      {impactByPos && maxImpact && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleImpactBreakdownClick()}
        >
          <div
            style={{ display: "flex", padding: 4 }}
            className={classes.playerImpactByPos}
          >
            <table style={{ width: "100%" }}>
              <thead></thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 0,
                    }}
                  >
                    <BsStar />
                  </td>
                  {Object.keys(Positions).map((p) => (
                    <td
                      key={p}
                      style={{
                        textAlign: "center",
                        padding: 0,
                        fontWeight: p === bio.position ? "bold" : "normal",
                      }}
                    >
                      {Positions[parseInt(p)]}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 0,
                    }}
                  >
                    {dec100Format(maxImpact)}
                  </td>
                  {impactByPos
                    .sort((a, b) => a.Pos_used - b.Pos_used)
                    .map((impact, i) => (
                      <td
                        key={i}
                        style={{
                          textAlign: "center",
                          padding: 0,
                          fontWeight:
                            impact.Pos_used === parseInt(bio.position || "")
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {dec100Format(impact.off_impact + impact.def_impact)}
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
            <TooltipItem
              tooltip={
                "The player's predicted net impact given the typical role for that position as well as in the role of the other NBA player that would best maximize this player's impact."
              }
              noListItem={true}
              placement={"top"}
            >
              <BsQuestionCircleFill
                style={{ verticalAlign: "top", marginTop: 2 }}
              />
            </TooltipItem>
          </div>
        </div>
      )}
    </div>
  );
}
