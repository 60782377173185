import React from "react";
import { Button } from "react-bootstrap";

export function TableCellButton(props: {
  onClick: () => void;
  children: JSX.Element;
}) {
  const { onClick, children } = props;

  return (
    <div style={{ textAlign: "center" }}>
      <Button
        onClick={() => onClick()}
        style={{
          lineHeight: 0,
          padding: 3,
          fontSize: "0.7em",
          verticalAlign: "top",
        }}
      >
        {children}
      </Button>
    </div>
  );
}
