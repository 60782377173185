import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { BsQuestionCircleFill } from "react-icons/bs";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { TooltipItem } from "../components/core/TooltipItem";
import { BiaUser } from "../../shared/routers/AdminRouter";
import { trpc } from "../util/tRPC";

const preferences: {
  name: string;
  details: string;
  options: string[];
  default: number;
}[] = [
  {
    name: "Search Priority",
    details:
      "Adjusting this preference allows you to adjust how results are prioritized in the search bar. Specifically you can control how much the order is influenced by the number of page views a player/team page has.",
    options: [
      "Don't include page views in result prioritization (legacy).",
      "Prioritize NBA results over everything else, then page views, then alphabetical.",
      "Prioritize 100% by page views.",
    ],
    default: 2,
  },
  {
    name: "Color Scheme",
    details:
      "Adjusting this customizes the color scheme used across BIA tables.",
    options: [
      "Classic BIA blue to red.",
      "Alternative color scheme used in post game reports.",
      "A blue to orange scheme inspired by the familiar CTG coloring.",
    ],
    default: 0,
  },
  {
    name: "Player Preview Cards",
    details: "Enables showing a player preview card on hover.",
    options: ["Disabled", "Enabled"],
    default: 0,
  },
  // TODO(chrisbu): Too experimental to even show for now, uncomment if we ever build this.
  // {
  //   name: "Shot Filters Assistant",
  //   details:
  //     "Allows setting shot filters using natural language queries. Very experimental!",
  //   options: [
  //     "Disable feature and hide the input.",
  //     "Show the assistant input box.",
  //   ],
  //   default: 0,
  // },
];

export function UserPage(props: { user: BiaUser }) {
  const utils = trpc.useContext();
  const { user } = props;
  const text = `${user.name}'s Settings`;

  const setPreference = trpc.user.setPreference.useMutation({
    onSettled: () => {
      utils.user.invalidate();
      utils.user.getPreferences.refetch();
    },
  });

  const { data } = trpc.user.getPreferences.useQuery({
    email: user.email,
  });

  if (!data) return null;

  return (
    <Page header={{ text: text }} title="Settings">
      <Row>
        <Col sm={12}>
          <Panel header="Preferences">
            {preferences.map((pref) => {
              const storedPrefVal = data.find(
                (d) => d.preferenceName === pref.name
              );
              const value =
                storedPrefVal === undefined
                  ? pref.default
                  : storedPrefVal.preferenceValue;
              return (
                <>
                  <Form.Label>
                    {pref.name}{" "}
                    <TooltipItem
                      tooltip={pref.details}
                      noListItem={true}
                      placement={"top"}
                    >
                      <BsQuestionCircleFill
                        style={{ verticalAlign: "top", marginTop: 4 }}
                      />
                    </TooltipItem>
                  </Form.Label>
                  <Form.Select
                    key={pref.name}
                    value={value}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      setPreference.mutate({
                        email: user.email,
                        preferenceName: pref.name,
                        preferenceValue: parseInt(evt.target.value),
                      });
                    }}
                    style={{ width: "auto" }}
                  >
                    {pref.options.map((o, i) => (
                      <option key={o} value={i}>
                        {o}
                      </option>
                    ))}
                  </Form.Select>
                </>
              );
            })}
          </Panel>
        </Col>
      </Row>
    </Page>
  );
}
