import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrayParam,
  encodeQueryParams,
  JsonParam,
  StringParam,
} from "use-query-params";
import { stringify } from "query-string";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import moment from "moment";

import { RadioButtonGroup } from "../core/RadioButtonGroup";
import { Line, LineChart } from "../chart/LineChart";
import { NbaDates } from "../../../shared/NbaDates";
import { ImpactOverTime } from "../../../shared/routers/ImpactRouter";
import { decFormat, seFormat, makePlusMinus } from "../../util/Format";
import { getSeasonFromDate } from "../../util/Util";
import { groupBy } from "../../../shared/util/Collections";
import AppContext from "../../../shared/AppContext";
import { lineChartColors } from "../../constants/ColorConstants";

const PERCENTILE_COLOR = "#31b323";

const GLOBAL_NET_IMPACT_COLOR = "#7A7A7A";
const NET_IMPACT_COLOR = "#474747";
const OFF_IMPACT_COLOR = "#ED6925";
const DEF_IMPACT_COLOR = "#781C6D";

export function ImpactOverTimeChart(props: {
  data: ImpactOverTime[];
  percentiles: number[];
  playerId: number;
  seasonsWithCameraImpact: Set<number>;
}) {
  const { percentiles, playerId, seasonsWithCameraImpact } = props;
  const allData = props.data;
  const navigate = useNavigate();

  const [seasonsToShow, setSeasonsToShow] = useState("last3");

  const allSeasons = new Set<number>();
  for (const d of allData) {
    allSeasons.add(d.Season);
  }

  const mostRecentNSeasons = new Set(
    [...allSeasons.values()]
      .sort((a, b) => a - b)
      .slice(-(parseInt(seasonsToShow.slice(-1)) || 1))
  );

  const showAllSeasons = seasonsToShow === "career";

  const data = showAllSeasons
    ? allData
    : allData.filter((d) => mostRecentNSeasons.has(d.Season));

  const seasons = showAllSeasons ? allSeasons : mostRecentNSeasons;

  const seasonMidPoints = [...seasons].map((s) => {
    const datesRecord = NbaDates[s];
    if (datesRecord) {
      // If it's the current season and it's not over yet choose date half way
      // between today and start of season.
      if (
        s === parseInt(AppContext.currentSeason) &&
        datesRecord.season.end > Date.now()
      ) {
        return (Date.now() + datesRecord.season.start) / 2;
      }

      return (datesRecord.season.start + datesRecord.season.end) / 2;
    }
    // If we are beyond the dates we have make a guess at season midpoint.
    return Date.parse(`1/25/${s.toString().slice(-2)}`);
  });

  const mod = Math.ceil(seasonMidPoints.length / 8);
  const xTicks = seasonMidPoints.filter((_, i) => i % mod === 0);

  const dataWithMs = data.map((d) => {
    return {
      ...d,
      dateInMs: Date.parse(d.gameDate),
      isGlobal: !seasonsWithCameraImpact.has(d.Season),
    };
  });

  const impactData = dataWithMs.filter((d) => !d.isGlobal);

  const globalImpactData = dataWithMs.filter((d) => d.isGlobal);

  const sortedData = dataWithMs.sort((a, b) => a.dateInMs - b.dateInMs);

  const yDomain = [
    Math.min(
      ...data.map((d) => Math.min(d.def_impact, d.off_impact, d.net_impact)),
      percentiles[0] || 0
    ),
    Math.max(
      ...data.map((d) => Math.max(d.def_impact, d.off_impact, d.net_impact)),
      percentiles[4] || 0
    ),
  ];

  const yTicks = [
    -0.06, -0.04, -0.02, 0, 0.02, 0.04, 0.06, 0.08, 0.1, 0.12,
  ].filter((t) => t > (yDomain[0] || 0) && t < (yDomain[1] || 0));

  const globalImpactLines: Line[] = [
    {
      label: "Net (Est.)",
      opacity: 0.75,
      strokeWidth: 1.5,
      color: GLOBAL_NET_IMPACT_COLOR,
      segments: Object.values(
        groupBy(globalImpactData, (d) => d.Season.toString())
      ).map((dataBySeason) =>
        dataBySeason.map((d) => {
          return { x: d.dateInMs, y: d.net_impact };
        })
      ),
    },
    {
      label: "Off (Est.)",
      opacity: 0.25,
      color: OFF_IMPACT_COLOR,
      segments: Object.values(
        groupBy(globalImpactData, (d) => d.Season.toString())
      ).map((dataBySeason) =>
        dataBySeason.map((d) => {
          return { x: d.dateInMs, y: d.off_impact };
        })
      ),
    },
    {
      label: "Def (Est.)",
      opacity: 0.25,
      color: DEF_IMPACT_COLOR,
      segments: Object.values(
        groupBy(globalImpactData, (d) => d.Season.toString())
      ).map((dataBySeason) =>
        dataBySeason.map((d) => {
          return { x: d.dateInMs, y: d.def_impact };
        })
      ),
    },
  ];

  const lines: Line[] = [
    {
      label: "Net",
      strokeWidth: 1.5,
      color: NET_IMPACT_COLOR,
      segments: Object.values(
        groupBy(impactData, (d) => d.Season.toString())
      ).map((dataBySeason) =>
        dataBySeason.map((d) => {
          return { x: d.dateInMs, y: d.net_impact };
        })
      ),
    },
    {
      label: "Off",
      color: OFF_IMPACT_COLOR,
      segments: Object.values(
        groupBy(impactData, (d) => d.Season.toString())
      ).map((dataBySeason) =>
        dataBySeason.map((d) => {
          return { x: d.dateInMs, y: d.off_impact };
        })
      ),
    },
    {
      label: "Def",
      color: DEF_IMPACT_COLOR,
      segments: Object.values(
        groupBy(impactData, (d) => d.Season.toString())
      ).map((dataBySeason) =>
        dataBySeason.map((d) => {
          return { x: d.dateInMs, y: d.def_impact };
        })
      ),
    },
  ];

  const dragTooltip = (dragToolTipData: {
    xVal: number;
    xVal2: number;
    data: { color: string; x: number; y: number; label: string }[];
    data2: { color: string; x: number; y: number; label: string }[];
  }) => {
    const xVal1 = dragToolTipData.xVal;
    const xVal2 = dragToolTipData.xVal2;
    const data1 = dragToolTipData.data;
    const data2 = dragToolTipData.data2;

    const netVal1 = data1.find((d) => d.label.includes("Net"))?.y;
    const netVal2 = data2.find((d) => d.label.includes("Net"))?.y;
    const netValDiff =
      netVal1 !== undefined && netVal2 !== undefined
        ? (netVal2 - netVal1) * 100
        : undefined;
    const offVal1 = data1.find((d) => d.label.includes("Off"))?.y;
    const offVal2 = data2.find((d) => d.label.includes("Off"))?.y;
    const offValDiff =
      offVal1 !== undefined && offVal2 !== undefined
        ? (offVal2 - offVal1) * 100
        : undefined;
    const defVal1 = data1.find((d) => d.label.includes("Def"))?.y;
    const defVal2 = data2.find((d) => d.label.includes("Def"))?.y;
    const defValDiff =
      defVal1 !== undefined && defVal2 !== undefined
        ? (defVal2 - defVal1) * 100
        : undefined;

    return (
      <div>
        <b>
          {`${moment(xVal1).format("MMM Do YYYY")} - ${moment(xVal2).format(
            "MMM Do YYYY"
          )}`}
        </b>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: global ? GLOBAL_NET_IMPACT_COLOR : NET_IMPACT_COLOR,
              width: 10,
              height: 10,
            }}
          ></div>
          <div>Net:</div>
          <div>{netVal1 === undefined ? "--" : decFormat(netVal1 * 100)}</div>
          <div>→</div>
          <div>{netVal2 === undefined ? "--" : decFormat(netVal2 * 100)}</div>
          {netValDiff !== undefined && (
            <b
              style={{
                color:
                  netValDiff < 0 ? lineChartColors.red : lineChartColors.green,
              }}
            >
              {makePlusMinus(decFormat)(netValDiff)}
            </b>
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: OFF_IMPACT_COLOR,
              width: 10,
              height: 10,
            }}
          ></div>
          <div>Off:</div>
          <div>{offVal1 === undefined ? "--" : decFormat(offVal1 * 100)}</div>
          <div>→</div>
          <div>{offVal2 === undefined ? "--" : decFormat(offVal2 * 100)}</div>
          {offValDiff !== undefined && (
            <b
              style={{
                color:
                  offValDiff < 0 ? lineChartColors.red : lineChartColors.green,
              }}
            >
              {makePlusMinus(decFormat)(offValDiff)}
            </b>
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: DEF_IMPACT_COLOR,
              width: 10,
              height: 10,
            }}
          ></div>
          <div>Def:</div>
          <div>{defVal1 === undefined ? "--" : decFormat(defVal1 * 100)}</div>
          <div>→</div>
          <div>{defVal2 === undefined ? "--" : decFormat(defVal2 * 100)}</div>
          {defValDiff !== undefined && (
            <b
              style={{
                color:
                  defValDiff < 0 ? lineChartColors.red : lineChartColors.green,
              }}
            >
              {makePlusMinus(decFormat)(defValDiff)}
            </b>
          )}
        </div>
      </div>
    );
  };

  const tooltip = (tooltipData: {
    data: { color: string; x: number; y: number; label: string }[];
  }) => {
    const firstTooltipData = tooltipData.data[0];
    if (!firstTooltipData) return null;

    const dataPoint = sortedData.find((d) => d.dateInMs >= firstTooltipData.x);
    if (!dataPoint) return null;

    const global = dataPoint.isGlobal;

    return (
      <div>
        <b>{moment(dataPoint.gameDate).format("MMM Do YYYY")}</b>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: global ? GLOBAL_NET_IMPACT_COLOR : NET_IMPACT_COLOR,
              width: 10,
              height: 10,
            }}
          ></div>
          Net: {decFormat(dataPoint.net_impact * 100)}
          {seFormat(dataPoint.net_se * 100 * 1.96)}
        </div>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: OFF_IMPACT_COLOR,
              width: 10,
              height: 10,
            }}
          ></div>
          Off: {decFormat(dataPoint.off_impact * 100)}
          {seFormat(dataPoint.off_se * 100)}
        </div>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: DEF_IMPACT_COLOR,
              width: 10,
              height: 10,
            }}
          ></div>
          Def: {decFormat(dataPoint.def_impact * 100)}
          {seFormat(dataPoint.def_se * 100)}
        </div>
        <div>{global && <b>(Global Estimate)</b>}</div>
      </div>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: -2, right: 0 }}>
        <RadioButtonGroup
          value={seasonsToShow}
          options={[
            { value: "last3", label: "3Y" },
            { value: "last5", label: "5Y" },
            { value: "last9", label: "9Y" },
            { value: "career", label: "Career" },
          ]}
          onChange={(val) => setSeasonsToShow(val)}
        />
      </div>
      <ParentSize>
        {({ width }) => (
          <LineChart
            width={width}
            height={width}
            margin={{ left: 30, bottom: 30 }}
            lines={globalImpactLines
              .concat(lines)
              .filter((l) => l.segments.length > 0)}
            showLegend={true}
            xTicks={xTicks}
            yTicks={yTicks}
            yTickFormat={(val) => ((val as number) * 100).toString()}
            xTickFormat={(val) => {
              const dateinMs = val as number;
              const season = getSeasonFromDate(
                moment(dateinMs).format("YYYY-MM-DD")
              );
              if (season) return season;
              return moment(dateinMs).format("YYYY");
            }}
            referenceRanges={[
              {
                opacity: 0.1,
                color: PERCENTILE_COLOR,
                y1: percentiles[1] || 0,
                y2: percentiles[3] || 0,
              },
              {
                opacity: 0.1,
                color: PERCENTILE_COLOR,
                y1: percentiles[0] || 0,
                y2: percentiles[4] || 0,
              },
            ]}
            referenceLines={[{ value: percentiles[2] || 0 }]}
            labelsForLegend={
              impactData.length
                ? ["Net", "Off", "Def"]
                : ["Net (Est.)", "Off (Est.)", "Def (Est.)"]
            }
            tooltip={tooltip}
            dragTooltip={dragTooltip}
            handleClick={(label: string, xVal: number) => {
              navigate(
                `/impact?${stringify(
                  encodeQueryParams(
                    {
                      playerA: StringParam,
                      playerB: StringParam,
                      offDef: StringParam,
                      metric: StringParam,
                      variable: StringParam,
                      players: JsonParam,
                      dates: ArrayParam,
                    },
                    {
                      playerA: "0",
                      playerB: "1",
                      offDef: "Net",
                      metric: "Impact",
                      variable: "Total",
                      players: [
                        {
                          playerId: playerId.toString(),
                          date: moment(xVal).format("yyyy-MM-DD"),
                        },
                        {
                          playerId: playerId.toString(),
                          date: moment().format("yyyy-MM-DD"),
                        },
                      ],
                    }
                  )
                )}`
              );
            }}
          />
        )}
      </ParentSize>
    </div>
  );
}
