import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import { useQueryParams, withDefault, StringParam } from "use-query-params";

import { Page } from "../components/core/Page";
import { Panel, PanelTabs } from "../components/core/Panel";
import { Restrict } from "../components/core/Restrict";
import { TeamRatings } from "../components/team/TeamRatings";
import { TeamMultiLeagueSchedule } from "../components/team/TeamMultiLeagueSchedule";
import { TeamMultiLeagueSeasonBox } from "../components/team/TeamMultiLeagueSeasonBox";
import { TeamRosterSkills } from "../components/team/TeamRosterSkills";
import { trpc } from "../util/tRPC";
import { groupBy } from "../../shared/util/Collections";
import { sumFromField } from "../util/Util";
import { seasonString } from "../util/Format";
import AppContext from "../../shared/AppContext";
import {
  TeamMultiLeagueDetails,
  MultiLeagueSeasonBox,
  SkillOverviewCombined,
} from "../../shared/routers/TeamRouter";
import { LeagueSeasonTeamSchedule } from "../../shared/routers/LeagueRouter";
import { TeamLogo } from "../components/team/TeamLogo";

export function MultiLeagueTeamPage() {
  const { id } = useParams();
  const [queryParams, setQueryParams] = useQueryParams({
    season: withDefault(StringParam, AppContext.currentSeason),
    league: withDefault(StringParam, ""),
  });
  const { season, league } = queryParams;

  const [seasonBoxActiveTab, setSeasonBoxActiveTab] = useState("seasonBox");

  const { data: teamDetails } = trpc.team.getTeamMultiLeagueDetails.useQuery({
    teamId: id,
  });

  const allLeagueSeasons = teamDetails
    ? addAllCompetitionLeagueSeasons(teamDetails)
    : undefined;

  const selectedLeagueSeason = allLeagueSeasons
    ? allLeagueSeasons.find((td) => {
        if (league !== "" && season !== "") {
          return td.leagueKey === league && td.season.toString() === season;
        } else if (league === "") {
          return td.season.toString() === season;
        } else if (season === "") {
          return td.leagueKey === league;
        }
      }) || allLeagueSeasons[0]
    : undefined;

  const selectedSeason =
    selectedLeagueSeason && selectedLeagueSeason.season.toString();
  const selectedLeague = selectedLeagueSeason && selectedLeagueSeason.leagueKey;

  // For team ratings we can't use all_competitions so pull the first league
  // key from team details.
  const { data: teamRatings } = trpc.team.getTeamRatings.useQuery({
    teamId: id,
    leagueKey:
      selectedLeague === "all_competitions" ? undefined : selectedLeague,
    season: selectedSeason,
  });

  const { data: seasonBox } = trpc.team.getMultiLeagueSeasonBox.useQuery({
    teamId: id,
    league: selectedLeague,
    season: selectedSeason,
  });

  const { data: schedule } = trpc.league.getLeagueSeasonTeamSchedule.useQuery({
    teamId: id,
    leagueKey: selectedLeague,
    season: selectedSeason,
  });

  const { data: skillOverviewCombined } =
    trpc.team.getTeamSkillOverviewCombined.useQuery({
      teamId: id,
      leagueKey: selectedLeague,
      season: selectedSeason,
    });

  if (!allLeagueSeasons || !selectedSeason || !selectedLeague) return null;

  const firstAllLeagueSeason = allLeagueSeasons[0];

  if (!firstAllLeagueSeason) return null;

  const teamName = allLeagueSeasons.length ? firstAllLeagueSeason.teamName : "";
  const espnId = allLeagueSeasons.length
    ? firstAllLeagueSeason.espnId
    : undefined;
  const teamIds = allLeagueSeasons.length
    ? firstAllLeagueSeason.teamIds
    : undefined;

  const winLossRecord = getWinLossRecord(schedule);

  const groupBySeason = groupBy(allLeagueSeasons, (ls) => ls.season.toString());

  const isNcaa = (teamDetails || []).some((td) => td.leagueKey === "ncaa");
  const isGLeague = (teamDetails || []).some(
    (td) => td.leagueKey === "g-league"
  );

  const pageHeader = (
    <div>
      <div>
        {/* ESPN IDs over 10000 seem to have no logos so skip those. */}
        {espnId && espnId < 10000 && isNcaa && (
          <TeamLogo espnId={espnId} inline={true} isNcaa={isNcaa} />
        )}
        {isGLeague && teamIds && (
          <TeamLogo idsId={teamIds} inline={true} isGLeague={isGLeague} />
        )}
        <h1>
          {teamName || "Team Browser"}
          {winLossRecord ? (
            <span
              style={{
                marginLeft: 10,
                fontWeight: 200,
                opacity: 0.8,
                fontSize: ".75em",
              }}
            >
              {winLossRecord}
            </span>
          ) : null}
        </h1>
        <Form.Select
          value={leagueSeasonValue(selectedSeason, selectedLeague)}
          onChange={(evt) => {
            setQueryParams({
              season: evt.target.value.split(LEAGUE_SEASON_SEPARATOR)[0],
              league: evt.target.value.split(LEAGUE_SEASON_SEPARATOR)[1],
            });
          }}
          style={{
            width: "auto",
            marginRight: "1rem",
            display: "inline-block",
          }}
        >
          {Object.keys(groupBySeason)
            .sort((a, b) => (b > a ? 1 : -1))
            .map((s) => (
              <optgroup key={s} label={seasonString(s) || s}>
                {(groupBySeason[s] || []).map((ls) => (
                  <option
                    key={leagueSeasonValue(ls.season.toString(), ls.league)}
                    value={leagueSeasonValue(ls.season.toString(), ls.league)}
                  >
                    {ls.league} ({ls.season})
                  </option>
                ))}
              </optgroup>
            ))}
        </Form.Select>
      </div>
    </div>
  );

  const renderSeasonBox = () => {
    if (!seasonBox || seasonBox.length === 0) return null;

    const seasonBoxPrepared =
      selectedLeague === "all_competitions"
        ? combinePlayerRows(seasonBox).sort((a, b) => b.mpg - a.mpg)
        : seasonBox;

    const tabs = {
      onClick: (tabKey: string) => setSeasonBoxActiveTab(tabKey),
      active: seasonBoxActiveTab,
      tabs: {
        seasonBox: {
          label: "Season Box Scores",
          content: (
            <TeamMultiLeagueSeasonBox
              data={seasonBoxPrepared}
              type={"perGame"}
            />
          ),
        },
        multiLeagueSeasonBoxPer100: {
          label: "Per 100 Possessions",
          content: (
            <TeamMultiLeagueSeasonBox
              data={seasonBoxPrepared}
              type={"per100Poss"}
            />
          ),
        },
      },
    };
    return <Panel tabs={tabs} />;
  };

  return (
    <Page header={{ component: pageHeader }} title={teamName}>
      <>
        <Row>
          <Col md={12}>{renderSeasonBox()}</Col>
        </Row>
        <Row>
          <Col md={6}>
            {schedule && schedule.length > 0 && (
              <Panel header="Games Played">
                <TeamMultiLeagueSchedule data={schedule} />
              </Panel>
            )}
          </Col>
          <Col lg={6} md={7} sm={12} xs={12}>
            {skillOverviewCombined && (
              <SkillOverviewCombinedPanel
                skillOverviewCombined={skillOverviewCombined}
                isNCAA={league === "ncaa"}
              />
            )}
          </Col>
        </Row>
        <Restrict roles={["bia"]}>
          {id && teamRatings && (
            <Row>
              <Col>
                <Panel header={"Team Ratings"}>
                  <TeamRatings
                    data={teamRatings}
                    highlightTeam={parseInt(id)}
                  />
                </Panel>
              </Col>
            </Row>
          )}
        </Restrict>
      </>
    </Page>
  );
}

function SkillOverviewCombinedPanel(props: {
  skillOverviewCombined: SkillOverviewCombined[];
  isNCAA: boolean;
}) {
  const { skillOverviewCombined, isNCAA } = props;

  const seasonData = skillOverviewCombined.filter((d) => d.source === "Season");
  // Check peak list to validate creation of tab.
  const skillData = skillOverviewCombined.filter((x) => x.source === "peak");

  const [skillOverviewCombinedTab, setSkillOverviewCombinedTab] = useState(
    isNCAA && skillData.length > 0 ? "peak" : "Season"
  );

  if (skillOverviewCombined.length === 0) return null;

  const skillTabs = {
    onClick: (tabKey: string) => setSkillOverviewCombinedTab(tabKey),
    active: skillOverviewCombinedTab,
    tabs: {
      Season: {
        label: "GLOBAL IMPACT ESTIMATES",
        content: <TeamRosterSkills data={seasonData} type="Season" />,
      },
    } as PanelTabs,
  };

  if (skillData.length > 0) {
    skillTabs.tabs["peak"] = {
      label: "DRAFT MODEL",
      content: <TeamRosterSkills data={skillData} type="peak" />,
    };
  }

  return <Panel tabs={skillTabs} />;
}

const LEAGUE_SEASON_SEPARATOR = "*";

function leagueSeasonValue(season: string, league: string) {
  return (
    season + LEAGUE_SEASON_SEPARATOR + league.replaceAll(" ", "_").toLowerCase()
  );
}

function getWinLossRecord(schedule: LeagueSeasonTeamSchedule[] | undefined) {
  if (!schedule) return;

  let wins = 0;
  let losses = 0;

  schedule.forEach((entry) => {
    if (entry.pointDifferential > 0) {
      wins += 1;
    } else if (entry.pointDifferential < 0) {
      losses += 1;
    }
  });

  return `${wins}-${losses}`;
}

function addAllCompetitionLeagueSeasons(
  leagueSeasons: TeamMultiLeagueDetails[]
) {
  const groupedBySeason = groupBy(leagueSeasons, (ls) => ls.season.toString());
  const newLeagueSeasons: TeamMultiLeagueDetails[] = [];
  for (const season of Object.keys(groupedBySeason)) {
    const gbs = groupedBySeason[season] || [];
    const firstGbs = gbs[0];
    if (firstGbs && gbs.length > 1) {
      newLeagueSeasons.push({
        teamIds: firstGbs.teamIds,
        espnId: firstGbs.espnId,
        teamId: firstGbs.teamId,
        teamName: firstGbs.teamName,
        season: parseInt(season),
        leagueKey: "all_competitions",
        league: "All Competitions",
      });
    }
    newLeagueSeasons.push(...gbs);
  }

  return newLeagueSeasons;
}

// If we are getting all competitions for a team we are going to get potentially
// multiple rows for the same player. This function combines those rows so that
// we only have a single row per player.
function combinePlayerRows(
  data: MultiLeagueSeasonBox[]
): MultiLeagueSeasonBox[] {
  const rowsByPlayer = groupBy(data, (row) => row.playerId.toString());
  return Object.values(rowsByPlayer).map((rows) => {
    const row1 = rows[0];
    const combinedRow: MultiLeagueSeasonBox = {
      playerId: row1 ? row1.playerId : 0,
      player: row1 ? row1.player : "",
      age: Math.max(
        ...rows.filter((r) => r.age !== null).map((r) => r.age || 0)
      ),
      jerseyNumber: row1 ? row1.jerseyNumber : null,
      Ht: row1 ? row1.Ht : null,
      Wt: row1 ? row1.Wt : null,
      wingSpan: row1 ? row1.wingSpan : null,
      eligibility: row1 ? row1.eligibility : null,
      offImpactPred: row1 ? row1.offImpactPred : null,
      defImpactPred: row1 ? row1.defImpactPred : null,
      selectionProbability: row1 ? row1.selectionProbability : null,
      gp: sumFromField("gp", rows) || 0,
      gs: sumFromField("gs", rows),
      min: sumFromField("min", rows) || 0,
      pts: sumFromField("pts", rows) || 0,
      roff: rows.reduce((acc, row) => acc + row.roff, 0),
      rtot: rows.reduce((acc, row) => acc + row.rtot, 0),
      ast: sumFromField("ast", rows),
      blk: sumFromField("blk", rows),
      stl: sumFromField("stl", rows),
      turn: sumFromField("turn", rows) || 0,
      pf: sumFromField("pf", rows),
      roffpct:
        (100 * rows.reduce((acc, row) => acc + row.roff, 0)) /
        rows.reduce((acc, row) => acc + row.roffavail, 0),
      rdefpct:
        (100 * rows.reduce((acc, row) => acc + row.rdef, 0)) /
        rows.reduce((acc, row) => acc + row.rdefavail, 0),
      turnrate:
        rows.reduce((acc, row) => acc + row.turn, 0) /
        rows.reduce((acc, row) => acc + row.PlayerPoss, 0),
      fg2m: rows.reduce((acc, row) => acc + row.fg2m, 0),
      fg2a: rows.reduce((acc, row) => acc + row.fg2a, 0),
      fg3m: rows.reduce((acc, row) => acc + row.fg3m, 0),
      fg3a: rows.reduce((acc, row) => acc + row.fg3a, 0),
      ftm: rows.reduce((acc, row) => acc + row.ftm, 0),
      fta: rows.reduce((acc, row) => acc + row.fta, 0),
      EstPossPlayed: rows.reduce((acc, row) => acc + row.EstPossPlayed, 0),
      ppp:
        rows.reduce((acc, row) => acc + row.pts, 0) /
        rows.reduce((acc, row) => acc + row.PlayerPoss, 0),
      fga: rows.reduce((acc, row) => acc + row.fga, 0),
      fgm: rows.reduce((acc, row) => acc + row.fgm, 0),
      ftarate:
        rows.reduce((acc, row) => acc + row.fta, 0) /
        rows.reduce((acc, row) => acc + row.PlayerPoss, 0),
      usg:
        rows.reduce((acc, row) => acc + row.PlayerPoss, 0) /
        rows.reduce((acc, row) => acc + row.UsgAvail, 0),
      mpg:
        rows.reduce((acc, row) => acc + row.min, 0) /
        rows.reduce((acc, row) => acc + row.gp, 0),
      PlayerPoss: rows.reduce((acc, row) => acc + row.PlayerPoss, 0),
      league: "All Competitions",
      UsgAvail: rows.reduce((acc, row) => acc + row.UsgAvail, 0),
      rdef: rows.reduce((acc, row) => acc + row.rdef, 0),
      rdefavail: rows.reduce((acc, row) => acc + row.rdefavail, 0),
      roffavail: rows.reduce((acc, row) => acc + row.roffavail, 0),
      season: row1 ? row1.season : 0,
      team: row1 ? row1.team : "",
      teamId: row1 ? row1.teamId : 0,
      teamIdsId: row1 ? row1.teamIdsId : null,
    };
    return combinedRow;
  });
}
