import React, { useMemo, useState } from "react";

import { LeagueTeamRanking } from "../../../shared/routers/LeagueRouter";
import { Table, createColumnHelper, SortingState } from "../core/Table";
import { StatRankRenderer } from "../table/renderers/StatRankRenderer";
import { Highlights, CELTICS_TEAM_ID } from "../../constants/AppConstants";
import {
  decFormat,
  decFormat2,
  makePlusMinus,
  pythFormat,
  pythDeltaFormat,
} from "../../util/Format";
import { average, pythagoreanWinExpectation } from "../../util/Util";
import { TeamTableCell } from "../core/TableCell";
import { extent as extentNew } from "../../util/Util";

// Refactored the extent method to return an object instead of array and this is
// just a shim to match old behavior so I can refactor later.
function extent<T>(data: T[], accessor: (d: T) => number) {
  const { min, max } = extentNew(data, accessor);
  return [min, max];
}

const columnHelper = createColumnHelper<LeagueTeamRanking>();

export function TeamRankingsTable(props: {
  data: LeagueTeamRanking[];
  selectedGroupSet: Set<string>;
}) {
  const { data, selectedGroupSet } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    const xPppLeague = average("XPPP", data);
    const defXpppLeague = average("defXPPP", data);
    const pppLeague = average("ppposs", data);
    const defPppLeague = average("def-ppposs", data);

    const netXpppDomain = extent(data, (d) => d.netXPPP || 0);
    const offXpppDomain = extent(data, (d) => d.XPPP || 0);
    const defXpppDomain = extent(data, (d) => d.defXPPP || 0).reverse();

    const netPppDomain = extent(data, (d) => d.netppposs);
    const offPppDomain = extent(data, (d) => d.ppposs);
    const defPppDomain = extent(data, (d) => d["def-ppposs"]).reverse();

    const offEfgDomain = extent(data, (d) => d["adj-pfg"]);
    const defEfgDomain = extent(data, (d) => d["def-adj-pfg"]).reverse();

    const offCrashersPerShotDomain = extent(
      data,
      (d) => d["crashersPerShot"] || 0
    );
    const defCrashersPerShotDomain = extent(
      data,
      (d) => d["opp-crashersPerShot"] || 0
    ).reverse();

    const offToPctDomain = extent(data, (d) => d["turn-rate"]).reverse();
    const defToPctDomain = extent(data, (d) => d["def-turn-rate"]);

    const offxToPctDomain = extent(data, (d) => d.xTovPct).reverse();
    const defxToPctDomain = extent(data, (d) => d["def-xTovPct"]);

    const offOrbPctDomain = extent(data, (d) => d.OffRPct);
    const defOrbPctDomain = extent(data, (d) => d["opp-OffRPct"]).reverse();

    const offxOrbPctDomain = extent(data, (d) => d.xOrbPct);
    const defxOrbPctDomain = extent(data, (d) => d["opp-xOrbPct"]).reverse();

    const offFtaRateDomain = extent(data, (d) => d["fta-rate"]);
    const defFtaRateDomain = extent(data, (d) => d["def-fta-rate"]).reverse();

    const offPpsDomain = extent(data, (d) => d.offPPS || 0);
    const defPpsDomain = extent(data, (d) => d.defPPS || 0).reverse();

    const offXppsDomain = extent(data, (d) => d.offXPPS || 0);
    const defXppsDomain = extent(data, (d) => d.defXPPS || 0).reverse();

    const offXppsLeagueDomain = extent(data, (d) => d.offXPPSLeague || 0);
    const defXppsLeagueDomain = extent(
      data,
      (d) => d.defXPPSLeague || 0
    ).reverse();

    const paceDomain = extent(data, (d) => d["avg-poss"]);

    const off2PctDomain = extent(data, (d) => d.tpp);
    const def2PctDomain = extent(data, (d) => d["def-tpp"]).reverse();

    const off3PctDomain = extent(data, (d) => d.PTP);
    const def3PctDomain = extent(data, (d) => d["def-ptp"]).reverse();

    const off3paPctDomain = extent(data, (d) => d["tpaperfga-rate"]);
    const def3paPctDomain = extent(
      data,
      (d) => d["opp-tpaperfga-rate"]
    ).reverse();

    const offFtPctDomain = extent(data, (d) => d.pft);
    const defFtPctDomain = extent(data, (d) => d["def-pft"]).reverse();

    const offAstFgmPctDomain = extent(data, (d) => d["aperfgm-rate"]);
    const defAstFgmPctDomain = extent(
      data,
      (d) => d["opp-aperfgm-rate"]
    ).reverse();

    const offStlDomain = extent(data, (d) => d["stl-rate"]).reverse();
    const defStlDomain = extent(data, (d) => d["def-stl-rate"]);

    const offBlkDomain = extent(data, (d) => d["blk-rate"]).reverse();
    const defBlkDomain = extent(data, (d) => d["def-blk-rate"]);

    let g = 0;
    return [
      columnHelper.accessor("teamName", {
        header: "Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.teamId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.group({
        id: "xPPP",
        meta: { group: g },
        header: "Expected Efficiency",
        columns: [
          columnHelper.accessor("netXPPP", {
            header: () => "xNET",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.ranknetXPPP,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: makePlusMinus(decFormat2),
                    showStat: true,
                    tooltip: () =>
                      pythFormat(
                        pythagoreanWinExpectation(
                          info.row.original.XPPP,
                          info.row.original.defXPPP
                        )
                      ),
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: netXpppDomain,
            },
          }),
          columnHelper.accessor("XPPP", {
            header: () => "xOFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankXPPP,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.XPPP,
                          info.row.original.defXPPP
                        ) -
                          pythagoreanWinExpectation(
                            xPppLeague,
                            info.row.original.defXPPP
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offXpppDomain,
            },
          }),
          columnHelper.accessor("defXPPP", {
            header: () => "xDEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankdefXPPP,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.XPPP,
                          info.row.original.defXPPP
                        ) -
                          pythagoreanWinExpectation(
                            info.row.original.XPPP,
                            defXpppLeague
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defXpppDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "PPP",
        meta: { group: g },
        header: "Efficiency",
        columns: [
          columnHelper.accessor("netppposs", {
            header: () => "NET",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.ranknetppposs,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: makePlusMinus(decFormat2),
                    showStat: true,
                    tooltip: () =>
                      pythFormat(
                        pythagoreanWinExpectation(
                          info.row.original.ppposs,
                          info.row.original["def-ppposs"]
                        )
                      ),
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: netPppDomain,
            },
          }),
          columnHelper.accessor("ppposs", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-ppposs"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.ppposs,
                          info.row.original["def-ppposs"]
                        ) -
                          pythagoreanWinExpectation(
                            pppLeague,
                            info.row.original["def-ppposs"]
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offPppDomain,
            },
          }),
          columnHelper.accessor("def-ppposs", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-ppposs"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                    tooltip() {
                      return pythDeltaFormat(
                        pythagoreanWinExpectation(
                          info.row.original.ppposs,
                          info.row.original["def-ppposs"]
                        ) -
                          pythagoreanWinExpectation(
                            info.row.original.ppposs,
                            defPppLeague
                          )
                      );
                    },
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defPppDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "efg",
        meta: { group: g },
        header: "EFG%",
        columns: [
          columnHelper.accessor("adj-pfg", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-adj-pfg"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offEfgDomain,
            },
          }),
          columnHelper.accessor("def-adj-pfg", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-adj-pfg"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defEfgDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "crashersPerShot",
        meta: { group: g },
        header: "Crashers/Shot",
        columns: [
          columnHelper.accessor("crashersPerShot", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-crashersPerShot"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offCrashersPerShotDomain,
            },
          }),
          columnHelper.accessor("opp-crashersPerShot", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-opp-crashersPerShot"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defCrashersPerShotDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "turnrate",
        meta: { group: g },
        header: "TO%",
        columns: [
          columnHelper.accessor("turn-rate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-turn-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offToPctDomain,
            },
          }),
          columnHelper.accessor("def-turn-rate", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-turn-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defToPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xTov",
        meta: { group: g },
        header: "xTO%",
        columns: [
          columnHelper.accessor("xTovPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-xTovPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offxToPctDomain,
            },
          }),
          columnHelper.accessor("def-xTovPct", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-xTovPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defxToPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "orb",
        meta: { group: g },
        header: "OR%",
        columns: [
          columnHelper.accessor("OffRPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-OffRPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offOrbPctDomain,
            },
          }),
          columnHelper.accessor("opp-OffRPct", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-opp-OffRPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defOrbPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xOrb",
        meta: { group: g },
        header: "xOR%",
        columns: [
          columnHelper.accessor("xOrbPct", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-xOrbPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offxOrbPctDomain,
            },
          }),
          columnHelper.accessor("opp-xOrbPct", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-opp-xOrbPct"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defxOrbPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "fta",
        meta: { group: g },
        header: "FTA Rate",
        columns: [
          columnHelper.accessor("fta-rate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-fta-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offFtaRateDomain,
            },
          }),
          columnHelper.accessor("def-fta-rate", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-fta-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defFtaRateDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "pps",
        meta: { group: g },
        header: "PPS",
        columns: [
          columnHelper.accessor("offPPS", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankoffPPS,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offPpsDomain,
            },
          }),
          columnHelper.accessor("defPPS", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankdefPPS,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defPpsDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xpps",
        meta: { group: g },
        header: "xPPS (Shot Quality)",
        columns: [
          columnHelper.accessor("offXPPS", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankoffXPPS,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offXppsDomain,
            },
          }),
          columnHelper.accessor("defXPPS", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankdefXPPS,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defXppsDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "xppsLeague",
        meta: { group: g },
        header: "xPPS Lg (Shot Difficulty)",
        columns: [
          columnHelper.accessor("offXPPSLeague", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankoffXPPSLeague,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offXppsLeagueDomain,
            },
          }),
          columnHelper.accessor("defXPPSLeague", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original.rankdefXPPSLeague,
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat2,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defXppsLeagueDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "pace",
        meta: { group: g },
        header: "Pace",
        columns: [
          columnHelper.accessor("avg-poss", {
            header: () => "Poss / 48",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-avg-poss"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: paceDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "2pct",
        meta: { group: g },
        header: "2P%",
        columns: [
          columnHelper.accessor("tpp", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-tpp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: off2PctDomain,
            },
          }),
          columnHelper.accessor("def-tpp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-tpp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: def2PctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "3pct",
        meta: { group: g },
        header: "3P%",
        columns: [
          columnHelper.accessor("PTP", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-ptp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: off3PctDomain,
            },
          }),
          columnHelper.accessor("def-ptp", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-ptp"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: def3PctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "3papct",
        meta: { group: g },
        header: "3PA%",
        columns: [
          columnHelper.accessor("tpaperfga-rate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-tpaperfga-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: off3paPctDomain,
            },
          }),
          columnHelper.accessor("opp-tpaperfga-rate", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-opp-tpaperfga-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: def3paPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "ftpct",
        meta: { group: g },
        header: "FT%",
        columns: [
          columnHelper.accessor("pft", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-pft"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offFtPctDomain,
            },
          }),
          columnHelper.accessor("def-pft", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-pft"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defFtPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "astfgmpct",
        meta: { group: g },
        header: "AST/FGM%",
        columns: [
          columnHelper.accessor("aperfgm-rate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-aperfgm-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: offAstFgmPctDomain,
            },
          }),
          columnHelper.accessor("opp-aperfgm-rate", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-opp-aperfgm-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: defAstFgmPctDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "stl",
        meta: { group: g },
        header: "STL%",
        columns: [
          columnHelper.accessor("stl-rate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-stl-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offStlDomain,
            },
          }),
          columnHelper.accessor("def-stl-rate", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-stl-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defStlDomain,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "blk",
        meta: { group: g },
        header: "BLK%",
        columns: [
          columnHelper.accessor("blk-rate", {
            header: () => "OFF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-blk-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: offBlkDomain,
            },
          }),
          columnHelper.accessor("def-blk-rate", {
            header: () => "DEF",
            cell: (info) => (
              <StatRankRenderer
                data={{
                  rank: info.row.original["rank-def-blk-rate"],
                  stat: info.getValue(),
                }}
                config={{
                  rendererOptions: {
                    fmt: decFormat,
                    showStat: true,
                  },
                }}
              />
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: defBlkDomain,
            },
          }),
        ],
      }),
    ];
  }, [data]);

  const hiddenColumns = {
    netXPPP: selectedGroupSet.has("xPPP"),
    XPPP: selectedGroupSet.has("xPPP"),
    defXPPP: selectedGroupSet.has("xPPP"),
    netppposs: selectedGroupSet.has("PPP"),
    ppposs: selectedGroupSet.has("PPP"),
    "def-ppposs": selectedGroupSet.has("PPP"),
    "adj-pfg": selectedGroupSet.has("efg"),
    "def-adj-pfg": selectedGroupSet.has("efg"),
    crashersPerShot: selectedGroupSet.has("crashers"),
    "opp-crashersPerShot": selectedGroupSet.has("crashers"),
    "turn-rate": selectedGroupSet.has("turnrate"),
    "def-turn-rate": selectedGroupSet.has("turnrate"),
    xTovPct: selectedGroupSet.has("xTov"),
    "def-xTovPct": selectedGroupSet.has("xTov"),
    OffRPct: selectedGroupSet.has("orb"),
    "opp-OffRPct": selectedGroupSet.has("orb"),
    xOrbPct: selectedGroupSet.has("xOrb"),
    "opp-xOrbPct": selectedGroupSet.has("xOrb"),
    "fta-rate": selectedGroupSet.has("fta"),
    "def-fta-rate": selectedGroupSet.has("fta"),
    offPPS: selectedGroupSet.has("pps"),
    defPPS: selectedGroupSet.has("pps"),
    offXPPS: selectedGroupSet.has("xpps"),
    defXPPS: selectedGroupSet.has("xpps"),
    offXPPSLeague: selectedGroupSet.has("xppsLg"),
    defXPPSLeague: selectedGroupSet.has("xppsLg"),
    "avg-poss": selectedGroupSet.has("pace"),
    tpp: selectedGroupSet.has("2pct"),
    "def-tpp": selectedGroupSet.has("2pct"),
    PTP: selectedGroupSet.has("3pct"),
    "def-ptp": selectedGroupSet.has("3pct"),
    "tpaperfga-rate": selectedGroupSet.has("3papct"),
    "opp-tpaperfga-rate": selectedGroupSet.has("3papct"),
    pft: selectedGroupSet.has("ftpct"),
    "def-pft": selectedGroupSet.has("ftpct"),
    "aperfgm-rate": selectedGroupSet.has("astfgmpct"),
    "opp-aperfgm-rate": selectedGroupSet.has("astfgmpct"),
    "stl-rate": selectedGroupSet.has("stl"),
    "def-stl-rate": selectedGroupSet.has("stl"),
    "blk-rate": selectedGroupSet.has("blk"),
    "def-blk-rate": selectedGroupSet.has("blk"),
  };

  const celticsIdx = data.findIndex((d) => d.teamId === CELTICS_TEAM_ID);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      hiddenColumns={hiddenColumns}
      rowColorMap={
        celticsIdx !== undefined
          ? {
              [celticsIdx]: {
                backgroundColor: "#F8FFF8",
                borderColor: "1px solid rgb(156, 203, 156)",
              },
            }
          : undefined
      }
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
