import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import cx from "classnames";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  FaBirthdayCake,
  FaBars,
  FaPlayCircle,
  FaTwitter,
} from "react-icons/fa";
import moment from "moment";

import AppContext from "../../../shared/AppContext";
import { Positions } from "../../constants/AppConstants";
import { TooltipItem } from "../core/TooltipItem";
import { PlayerPhoto } from "./PlayerPhoto";
import { PlayerImpact } from "./PlayerImpact";
import {
  PlayerBio,
  PlayerDraftModel,
  PlayerMeasurements,
  PlayerModeledMeasurements,
} from "../../../shared/routers/PlayerRouter";
import { RosterPlayer } from "../../../shared/routers/RosterRouter";
import { PlayerList } from "./PlayerList";
import { Icon } from "../core/Icon";
import {
  dateFormat,
  decFormat,
  ordinalFormat,
  footFormat,
  poundsFormat,
} from "../../util/Format";
import { trpc } from "../../util/tRPC";
import { groupBy } from "../../../shared/util/Collections";
import { PlayerStatusMessage } from "./PlayerStatusMessage";
import { PlayerInjuryMessage } from "./PlayerInjuryMessage";
import { PlayerTrendingMessage } from "./PlayerTrendingMessage";
import { TeamTableCell } from "../core/TableCell";
import { BANNED_PLAYER_IDS } from "../../constants/BannedPlayers";
import { redHigh } from "../../constants/ColorConstants";

const useStyles = makeStyles(() =>
  createStyles({
    pageHeader: {
      gridColumn: "1 / span 1",
      gridRow: "1 / span 1",
      marginBottom: 5,
      ["@media (max-width:768px)"]: {
        gridColumn: "1 / span 2",
        gridRow: "unset",
      },
    },
    playerBio: {
      marginTop: 10,
      ["@media (max-width:768px)"]: {
        textAlign: "center",
        marginBottom: 20,
      },
    },
    playerSummary: {
      marginLeft: 130,
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridTemplateRows: "41px auto auto",
      gridAutoFlow: "column",
      ["@media (max-width:768px)"]: {
        marginLeft: 0,
        gridTemplateColumns: "1fr",
      },
    },
    photoContainer: {
      position: "absolute",
      left: 18,
      ["@media (max-width:768px)"]: {
        position: "static",
        margin: "auto",
      },
    },
    impactContainer: {
      gridColumn: "1 / span 1",
      gridRow: "2 / span 1",
      ["@media (max-width:768px)"]: {
        gridColumn: "unset",
        gridRow: "unset",
        margin: "0 auto",
      },
    },
    playerBioDetails: {
      gridRow: "span 2",
      padding: "5px 5px 5px 28px",
      ["@media (max-width:768px)"]: {
        gridColumn: "1 / span 2",
        gridRow: "unset",
        padding: 5,
      },
    },
    noImpact: {
      padding: "0px 5px 5px 5px",
      marginottom: 0,
    },
    headerInlineList: {
      fontSize: "1.2em",
      marginBottom: 9,
      paddingLeft: 0,
      listStyle: "none",
      marginLeft: -5,
      "& li": {
        display: "inline-block",
        paddingLeft: 5,
        paddingRight: 5,
      },
      "& li::after": {
        content: "'•'",
        color: "rgba(255,255,255,.15)",
        marginLeft: 10,
      },
      "& li:last-child::after": {
        content: "''",
        margin: 0,
      },
    },
    rosterPopover: {
      padding: "9px 14px",
    },
    rosterPopoverTeamName: {
      padding: "5px 15px 7px",
      background: "#FFF",
      margin: "-5px -15px 8px",
      display: "block",
      fontWeight: 600,
      textAlign: "left",
      borderBottom: "1px solid #eee",
    },
    bioLine: {
      marginBottom: 4,
      "&:last-child": {
        marginBottom: 10,
      },
    },
    teamListIcon: {
      fontSize: ".9em",
      opacity: 0.8,
      marginRight: 2,
    },
    labelInHeader: {
      backgroundColor: "rgba(255,255,255,.05)",
      color: "rgba(255,255,255,.75)",
      marginRight: 4,
      display: "inline",
      padding: "0.2em 0.6em 0.3em",
      fontSize: "75%",
      fontWeight: 700,
      lineHeight: 1,
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: "0.25em",
    },
  })
);

export function PlayerBioHeader(props: {
  data: PlayerBio;
  teamRoster?: RosterPlayer[];
  measurements?: PlayerMeasurements[];
  modeledMeasurements?: PlayerModeledMeasurements[];
  draftModel?: PlayerDraftModel[];
  handleImpactBreakdownClick: () => void;
}) {
  const classes = useStyles();
  const bio = props.data;
  const teamRoster = props.teamRoster;
  const playerId = bio.playerId;
  const handleImpactBreakdownClick = props.handleImpactBreakdownClick;

  const { data: agents, isLoading: isAgentDataLoading } =
    trpc.player.getPlayerAgents.useQuery({ playerId });

  let position: string | number = Math.round(parseFloat(bio.position || ""));
  if (isNaN(position)) {
    position = "";
  } else {
    position = Positions[position] || "";
  }

  const modeledMeasurements =
    props.modeledMeasurements && props.modeledMeasurements[0];

  let height = "";
  let heightValue;
  if (modeledMeasurements && modeledMeasurements.heightWithShoes) {
    heightValue = Math.round(modeledMeasurements.heightWithShoes * 2) / 2;
  } else if (bio.height) {
    heightValue = bio.height;
  } else if (props.measurements) {
    // Find most recent heightWithShoes measurement that we have.
    heightValue = props.measurements
      .sort((a, b) => b.measurementDate - a.measurementDate)
      .find((m) => m.heightWithShoes && m.heightWithShoes > 0)?.heightWithShoes;
  }

  if (heightValue) {
    height = footFormat(heightValue);
  }

  const noImpact =
    (!bio.showOnTop ||
      (bio.season &&
        bio.season < Number.parseInt(AppContext.currentSeason) - 1)) &&
    bio.showOnTop !== 0;

  const drafted = [];
  let showingEligibility = false;
  if (bio.draftPick == null) {
    if (bio.eligibility) {
      drafted.push(bio.eligibility);
      showingEligibility = true;
    } else if (bio.pastDraft) {
      drafted.push("Undrafted");
    }
  } else {
    drafted.push(<em key={0}>{"#" + bio.draftPick}</em>, " pick");
  }

  if (bio.draftYear) {
    drafted.push(" in ");
    // Link to Draft Class page.
    drafted.push(
      <em key={2}>
        <Link to={`/draft-class/${bio.draftYear}`}>{bio.draftYear}</Link>
      </em>
    );
  }

  if (bio.school) {
    // Make the school string prettier when it has no space after comma.
    drafted.push(
      showingEligibility ? " at " : " from ",
      <em key={3}>{bio.school.split(",").join(", ")}</em>
    );
  } else if (bio.Hometown) {
    drafted.push(
      " from ",
      <em key={3}>{bio.Hometown + (bio.Country ? `, ${bio.Country}` : "")}</em>
    );
  }

  const draftModel = props.draftModel;
  if (draftModel && draftModel.length) {
    const pctFormat = (d: number | null) =>
      d == null ? "--" : Math.round(d * 100) + "%";
    // Get most recent entry.
    const latestDraftModel = draftModel.sort((a, b) => b.year - a.year)[0];
    const pNbaString = draftModel
      .map((d) => (d.pNba == null ? null : `${d.year} ${pctFormat(d.pNba)}`))
      .filter((d) => d !== null)
      .join(" - ");

    const draftModelDetails = latestDraftModel ? (
      <span key={"draftModel"} className="draft-model-container">
        <Link to={`/draft-class/${latestDraftModel.year}`}>
          {latestDraftModel.off_pred && (
            <span
              className={classes.labelInHeader}
              title={`Predicted offensive impact would be ${ordinalFormat(
                latestDraftModel.typical_rank_off || 0
              )}-best in a typical draft. `}
            >
              Off. <em>{decFormat(latestDraftModel.off_pred)}</em>
            </span>
          )}
          {latestDraftModel.def_pred && (
            <span
              className={classes.labelInHeader}
              title={`Predicted defensive impact would be ${ordinalFormat(
                latestDraftModel.typical_rank_def || 0
              )}-best in a typical draft. `}
            >
              Def. <em>{decFormat(latestDraftModel.def_pred)}</em>
            </span>
          )}
          {latestDraftModel.net_pred && (
            <span
              className={classes.labelInHeader}
              title={`Predicted net impact would be ${ordinalFormat(
                latestDraftModel.typical_rank_net || 0
              )}-best in a typical draft. `}
            >
              Net <em>{decFormat(latestDraftModel.net_pred)}</em>
            </span>
          )}
          <span
            className={classes.labelInHeader}
            title={`Probability of ever playing in the NBA: ${pNbaString}. ${pctFormat(
              latestDraftModel.pNba
            )} would be ${ordinalFormat(
              latestDraftModel.typical_rank_pnba || 0
            )}-best in a typical draft. `}
          >
            NBA <em>{pctFormat(latestDraftModel.pNba)}</em>
          </span>
        </Link>
      </span>
    ) : (
      ""
    );

    drafted.push(" ", draftModelDetails);
  }

  let weight = bio.weight;
  if (props.measurements) {
    const weightMeasurements = props.measurements
      .filter((m) => m.weight !== null)
      .map((m) => m.weight);
    if (weightMeasurements.length) {
      weight = weightMeasurements[weightMeasurements.length - 1] || null;
    }
  }

  let wingspan, standReach, wingspanDelta;

  if (props.measurements && modeledMeasurements) {
    const wingspanValue = modeledMeasurements.wingspan;

    wingspan = wingspanValue && footFormat(wingspanValue);

    // Use no shoes height for wingspan delta (as requested by DLew).
    let heightNoShoesValue = modeledMeasurements.heightNoShoes;

    if (!heightNoShoesValue && heightValue) {
      // As per Drew get no shoes height by minus -1.25.
      heightNoShoesValue = heightValue - 1.25;
    }

    if (heightNoShoesValue && wingspanValue) {
      // Calculate and format wingspan delta from height.
      wingspanDelta =
        Math.round(10 * (wingspanValue - heightNoShoesValue)) / 10;
      if (wingspanDelta > 0) {
        wingspanDelta = "+" + wingspanDelta;
      }
      wingspanDelta += '"';
    } else {
      wingspanDelta = "";
    }

    if (wingspanDelta !== "") {
      wingspanDelta = `(${wingspanDelta})`;
    }

    if (modeledMeasurements.standReach) {
      standReach = footFormat(modeledMeasurements.standReach);
    }
  }

  const agentInfo = [];
  // While data is loading show some placeholder text to prevent jumping.
  if (isAgentDataLoading) {
    agentInfo.push("Represented by ");
  }
  // See if we have RealGM data first.)
  else if (agents && agents.length) {
    agentInfo.push("Represented by ");

    const agentsByAgency = groupBy(agents, (a) => a.agencyName || "");
    for (let i = 0; i < Object.keys(agentsByAgency).length; i++) {
      const agencyName = Object.keys(agentsByAgency)[i];
      if (agencyName === undefined) continue;
      const agentsInAgency = agentsByAgency[agencyName] || [];
      if (i > 0) {
        agentInfo.push(" and ");
      }
      for (let j = 0; j < agentsInAgency.length; j++) {
        if (j > 0) {
          if (j < agentsInAgency.length - 1) {
            agentInfo.push(", ");
          } else {
            agentInfo.push(" and ");
          }
        }
        const agent = agentsInAgency[j];
        if (agent) {
          const agentName = agent.agentName;
          agentInfo.push(<em key={agentName}>{agentName}</em>);
        }
      }
      if (agencyName !== "") {
        agentInfo.push(" at ");
        agentInfo.push(<em key={agencyName}>{agencyName}</em>);
      }
    }
    // Fall back to what we get from PCMS if agent data finished loading
    // and there was nothing there.
  } else if (bio.agent || bio.agency) {
    agentInfo.push("Represented by ");
    if (bio.agent) {
      agentInfo.push(<em key={0}>{bio.agent}</em>);

      if (bio.agency) {
        agentInfo.push(" at ");
      }
    }
    if (bio.agency) {
      agentInfo.push(<em key={1}>{bio.agency}</em>);
    }
  }

  // Add in high school and hometown.
  const hometown = [];
  const home = [bio.Hometown, bio.State, bio.Country]
    .filter((h) => !!h)
    .join(", ");
  hometown.push("From ");
  if (bio.HSJC) {
    hometown.push(<em key={0}>{bio.HSJC}</em>);
    if (home.length) {
      hometown.push(" in ");
    }
  }
  if (home.length) {
    hometown.push(<em key={1}>{home}</em>);
  }

  let measurementsList;
  if (wingspan || standReach) {
    let wingspanIsEstimate = false;
    if (modeledMeasurements && modeledMeasurements.whichAreEstimated) {
      wingspanIsEstimate =
        modeledMeasurements.whichAreEstimated
          .toLowerCase()
          .indexOf("wingspan") >= 0;
    }

    measurementsList = (
      <ul
        className={cx("list-inline", classes.headerInlineList)}
        style={{ marginTop: -9 }}
      >
        {wingspan && (
          <TooltipItem
            arrow={false}
            tooltip={wingspanIsEstimate ? "Approximate Wingspan" : "Wingspan"}
          >
            <Icon type="wingspan" />
            {`${wingspanIsEstimate ? "≈" : ""}${wingspan} ${wingspanDelta}`}
          </TooltipItem>
        )}
        {standReach && (
          <TooltipItem arrow={false} tooltip="Standing Reach">
            <Icon type="standing-reach" />
            {standReach}
          </TooltipItem>
        )}
      </ul>
    );
  }

  let birthDate;
  let isBirthday;
  if (bio.birthDate) {
    birthDate = dateFormat(moment(bio.birthDate).toDate());
    isBirthday = birthDate.slice(0, 5) === dateFormat(new Date()).slice(0, 5);
  }

  let teamItem;

  if (teamRoster) {
    const roster = (
      <div className={classes.rosterPopover}>
        <Link
          className={classes.rosterPopoverTeamName}
          to={`/team/${bio.teamid}`}
        >
          {bio.teamshortname}
        </Link>
        <PlayerList players={teamRoster} />
      </div>
    );

    if (teamRoster.length > 0) {
      teamItem = (
        <li>
          <OverlayTrigger
            placement="bottom"
            rootClose
            trigger="click"
            overlay={<Popover>{roster}</Popover>}
          >
            <span title="Click to show roster">
              <FaBars className={classes.teamListIcon} />
            </span>
          </OverlayTrigger>
          <Link className="team-name" to={`/team/${bio.teamid}`}>
            {bio.teamabbreviation}
          </Link>
        </li>
      );
    } else {
      teamItem = (
        <TooltipItem arrow={false} tooltip="Team">
          <Link className="team-name" to={`/team/${bio.teamid}`}>
            {bio.teamabbreviation}
          </Link>
        </TooltipItem>
      );
    }
  } else {
    teamItem = (
      <TooltipItem arrow={false} tooltip="Team">
        {bio.teamabbreviation}
      </TooltipItem>
    );
  }

  // Remove team for voluntarily retired players.
  if (bio.playerStatus === "VRET") {
    teamItem = null;
  }

  const mostRecentClipDate = bio.mostRecentClipDate;

  const targetVideoStartDate = moment(new Date()).subtract(15, "months");

  // If most recent clip date is not null but it is before the target video
  //start date, use that (-15 months) instead of the target video start date.
  // This way the link we open to the video page will always have clips.
  const actualVideoStartDate =
    mostRecentClipDate === null ||
    moment(mostRecentClipDate).isAfter(targetVideoStartDate)
      ? targetVideoStartDate
      : moment(mostRecentClipDate).subtract(15, "months");

  const videoStartDate = actualVideoStartDate.format("YYYY-MM-DD");

  let mostRecentTeamStr = undefined;
  // Skip NBA players whose last game was for their NBA team.
  if (
    bio.lastGameTeamIdIds !== null &&
    bio.lastGameLeague !== null &&
    bio.lastGameTeamId !== null &&
    bio.lastGameSeason !== null &&
    bio.lastGameTeam !== null &&
    bio.lastGameTeamIdIds !== bio.teamid
  ) {
    mostRecentTeamStr = (
      <div>
        Last played for{" "}
        <b>
          <TeamTableCell
            useSpan={true}
            name={bio.lastGameTeam}
            id={bio.lastGameTeamId}
            ids={bio.lastGameTeamIdIds || undefined}
            league={bio.lastGameLeague.replaceAll(" ", "_").toLowerCase()}
            season={bio.lastGameSeason.toString()}
          />
        </b>
      </div>
    );
  }

  return (
    <div className={classes.playerBio}>
      <div className={classes.photoContainer}>
        <PlayerPhoto
          ids={bio.idIds || undefined}
          playerId={playerId}
          dxPhoto={bio.Headshot}
        />
      </div>
      <div className={classes.playerSummary}>
        <h2
          className={cx(classes.pageHeader, {
            [classes.noImpact]: noImpact,
          })}
        >
          {bio.fullName}
        </h2>
        {((bio.season &&
          bio.season >= parseInt(AppContext.currentSeason) - 1) ||
          bio.showOnTop === 0) && (
          <div className={classes.impactContainer}>
            <PlayerImpact
              bio={bio}
              handleImpactBreakdownClick={handleImpactBreakdownClick}
            />
          </div>
        )}
        <div
          className={cx(classes.playerBioDetails, {
            [classes.noImpact]: noImpact,
          })}
        >
          <ul className={cx("list-inline", classes.headerInlineList)}>
            {bio.position && (
              <TooltipItem
                arrow={false}
                tooltip={decFormat(parseFloat(bio.position)) || ""}
              >
                {position}
              </TooltipItem>
            )}
            {bio.teamid && teamItem}
            {bio.age && birthDate && (
              <TooltipItem arrow={false} tooltip={birthDate}>
                {decFormat(bio.age) + "yo"} {isBirthday && <FaBirthdayCake />}
                {bio.listedAge !== null &&
                  `(Listed: ${decFormat(bio.listedAge) + "yo"})`}
              </TooltipItem>
            )}
            {height && (
              <TooltipItem arrow={false} tooltip="Height">
                {height}
              </TooltipItem>
            )}
            {weight && (
              <TooltipItem arrow={false} tooltip="Weight">
                {poundsFormat(weight)}
              </TooltipItem>
            )}
            {bio.eligibility && (
              <TooltipItem arrow={false} tooltip="Class">
                {bio.eligibility}
              </TooltipItem>
            )}
            {mostRecentClipDate !== null && (
              <TooltipItem arrow={false} tooltip={"Open player's videos"}>
                <a
                  href={`/video?dateFrom=${videoStartDate}&players=${playerId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaPlayCircle />
                </a>
              </TooltipItem>
            )}
            {bio.TwitterHandle && (
              <TooltipItem arrow={false} tooltip={"Twitter Account"}>
                <a
                  href={`https://twitter.com/${bio.TwitterHandle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter />
                </a>
              </TooltipItem>
            )}
          </ul>
          {measurementsList}
          {!!drafted.length && <p className={classes.bioLine}>{drafted}</p>}
          {!!agentInfo.length && <p className={classes.bioLine}>{agentInfo}</p>}
          {hometown.length > 1 && <p className={classes.bioLine}>{hometown}</p>}
          <PlayerStatusMessage bio={bio} />
          {BANNED_PLAYER_IDS.has(playerId) ? (
            <b style={{ color: redHigh }}>Banned for life</b>
          ) : (
            <>
              {mostRecentTeamStr}
              <PlayerInjuryMessage playerId={playerId} />
              <PlayerTrendingMessage playerId={playerId} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
