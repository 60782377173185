import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Button } from "react-bootstrap";

import { BiaUser } from "../../shared/routers/AdminRouter";

async function loginUser(credentials: Record<string, string>) {
  return fetch("/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      display: "block",
      width: "100%",
      height: 34,
      padding: "6px 12px",
      fontSize: 14,
      lineHeight: 1.42857,
      margin: 0,
      color: "black",
      backgroundColor: "#fff",
      backgroundImage: "none",
      border: "1px solid #ccc",
      borderRadius: 4,
      boxShadow: "inset 0 1px 1px rgb(0 0 0 / 8%)",
      transition: "border-color ease-in-out .15s,box-shadow ease-in-out .15s",
    },
    formGroup: {
      marginBottom: 15,
    },
    formLabel: {
      display: "inline-block",
      maxWidth: "100%",
      marginBottom: 5,
      fontWeight: 700,
    },
    fullWidth: {
      width: "100%",
    },
    loginBox: {
      textAlign: "left",
      width: 300,
      background: "rgba(255,255,255,0.1)",
      margin: "50px auto 0",
      padding: 20,
      borderRadius: 10,
    },
    loginOuter: {
      textAlign: "center",
      margin: "120px 0",
    },
    mainLogo: {
      width: 300,
      background: "#ffffff",
      borderRadius: "50%",
      padding: 5,
      border: 0,
      verticalAlign: "middle",
    },
  })
);

export function LoginPage(props: { setUser: (user: BiaUser) => void }) {
  const classes = useStyles();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [message, setMessage] = useState<string>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      setMessage("Missing email or password.");
      return;
    }
    const response = await loginUser({
      email: email.toLowerCase(),
      password,
    });
    if (response.message) {
      setMessage(response.message);
    } else {
      props.setUser(response.user);
    }
  };

  return (
    <div className={classes.loginOuter}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      body {
        background-color: #363747;
        padding-top: 0px;
        color: white;
        font-size: 14px;
        line-height: 1.42857;
      }
      * { box-sizing: border-box; }
      `,
        }}
      />
      <img src="/assets/img/BIA.png" className={classes.mainLogo}></img>
      <div className={classes.loginBox}>
        <form onSubmit={handleSubmit}>
          <p>{message}</p>
          <div className={classes.formGroup}>
            <label className={classes.formLabel}>Email</label>
            <input
              className={classes.formControl}
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={classes.formGroup}>
            <label className={classes.formLabel}>Password</label>
            <input
              className={classes.formControl}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button type="submit" variant="light" className={classes.fullWidth}>
            Login
          </Button>
        </form>
      </div>
    </div>
  );
}
